import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { AuthService } from '@app/shared/services';
import { TargetResourceService } from '@state/target-resource';
import { UserQuery, UserService } from '@state/user';

@Injectable({ providedIn: 'root' })
export class TargetResourceGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private targetResourceService: TargetResourceService,
    private userQuery: UserQuery,
    private userService: UserService
  ) {}

  canActivate(): Observable<boolean> | boolean {
    let loggedIn = this.authService.loggedIn();

    if (loggedIn) {
      if (!this.userQuery.getValue().hasFetchedUser) this.userService.setUserFromAuth();
      return this.targetResourceService.getTargetResource$().pipe(
        map((targetResourceResponse) => {
          this.targetResourceService.navigateBasedOnResponse(targetResourceResponse);
          return false;
        })
      );
    } else {
      let redirectUrl = window.location.href;
      void this.router.navigate(['/login'], { queryParams: { redirectUrl } });
      return false;
    }
  }
}
