import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import { Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { ID } from '@state/base';
import { PusherService } from '@state/pusher';

import {
  ReportPusherMessage,
  ReportResponse,
  ReportsResponse,
  ReportStatus,
  ReportType
} from './report.model';
import { ReportRepository } from './report.repository';

const REPORTS_URL = `${environment.apiUrl}/v1/reports`;

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
    private reportRepo: ReportRepository
  ) {}

  getReports$(params: HttpParams, headers: HttpHeaders): Observable<ReportsResponse> {
    return this.http.get<ReportsResponse>(REPORTS_URL, { headers, params });
  }

  getAndStoreReports$(entity_id: ID, provider_id?: string): Observable<ReportsResponse> {
    let params = new HttpParams({ fromObject: { entity_id } });
    let headers: HttpHeaders;
    if (provider_id) headers = new HttpHeaders({ 'Provider-Id': provider_id });

    return this.getReports$(params, headers).pipe(tap({ next: (data) => this.reportRepo.updateStore(data) }));
  }

  queueReport$({
    channel,
    entity_id,
    filters,
    provider_id,
    report_type
  }: {
    channel: string;
    entity_id: ID;
    filters?: { search_term: string };
    provider_id?: string;
    report_type: ReportType;
  }): Observable<ReportResponse> {
    let headers: HttpHeaders;
    if (provider_id) headers = new HttpHeaders({ 'Provider-Id': provider_id });

    return this.http.post<ReportResponse>(
      REPORTS_URL,
      { channel, entity_id, filters, report_type },
      { headers }
    );
  }

  subscribeToReportStatus(channelID: string): void {
    let channel = this.pusherService.pusher.subscribe(channelID);

    channel.bind('report_status', (message: ReportPusherMessage) => {
      let reportStatus: ReportStatus = message.status;

      if (reportStatus === ReportStatus.Complete) {
        this.reportRepo.updateStore({ reportUrl: message.html_signed_url });
      }

      channel.unbind();
      channel.unsubscribe();
    });
  }
}
