import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizationLayoutComponent } from '@app/layout/authorization-layout';

import { LoginComponent } from './login.component';

export const routes: Routes = [
  {
    children: [{ component: LoginComponent, path: '' }],
    component: AuthorizationLayoutComponent,
    path: ''
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class LoginRoutingModule {}
