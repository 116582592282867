import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { FormValues } from '@state/form-wizard';

import { Task } from './task.model';
import { TaskStore } from './task.store';

export interface TaskAssignParams {
  params: {
    email?: string;
    message?: string;
    name?: string;
    subject?: string;
    user_id?: ID;
  };
  task_id: ID;
  workflow_id: ID;
}

export interface TaskFormParams {
  complete_task?: boolean;
  updated_form_values?: FormValues;
  form_values: FormValues;
  section_form_values?: FormValues;
  task_id: string;
  workflow_id: string;
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private http: HttpClient, private taskStore: TaskStore) {}

  assign$({ params, task_id, workflow_id }: TaskAssignParams): Observable<null> {
    let url = `${environment.apiUrl}/v1/workflow/${workflow_id}/tasks/${task_id}/assign`;
    return this.http.post<null>(url, params);
  }

  get$({ task_id, workflow_id }: { task_id: ID; workflow_id: ID }): Observable<Task> {
    let url = `${environment.apiUrl}/v1/workflow/${workflow_id}/tasks/${task_id}`;
    return this.http.get<Task>(url);
  }

  getAndStore$({ task_id, workflow_id }: { task_id: ID; workflow_id: ID }): Observable<Task> {
    return this.get$({ task_id, workflow_id }).pipe(
      tap({
        next: (task) => {
          this.taskStore.update({ task });
        }
      })
    );
  }

  submitForm$({
    complete_task,
    updated_form_values,
    form_values,
    task_id,
    workflow_id,
    section_form_values
  }: TaskFormParams): Observable<Task> {
    let url = `${environment.apiUrl}/v1/workflow/${workflow_id}/tasks/${task_id}/submit`;
    return this.http.post<Task>(url, {
      complete_task,
      form_values,
      section_form_values,
      updated_form_values
    });
  }

  submitFormAndStore$(taskFormParams: TaskFormParams): Observable<Task> {
    return this.submitForm$(taskFormParams).pipe(
      tap({
        next: (task) => {
          this.taskStore.update({
            formValues: { ...taskFormParams.form_values, ...task.defaults },
            task
          });
        }
      })
    );
  }

  updateFormAndVehicle(task: Task): void {
    let { form, vehicle } = task;
    this.taskStore.update({ form, vehicle });
  }

  reset(): void {
    this.taskStore.reset();
  }
}
