import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// Used to specify the entities which should be escaped by the Froala editor.
let entities =
  '&iexcl;&cent;&pound;&curren;&yen;&brvbar;&sect;&uml;&copy;&ordf;&laquo;&not;&shy;&reg;&macr;&deg;&plusmn;&sup2;&sup3;&acute;&micro;&para;&middot;&cedil;&sup1;&ordm;&raquo;&frac14;&frac12;&frac34;&iquest;&Agrave;&Aacute;&Acirc;&Atilde;&Auml;&Aring;&AElig;&Ccedil;&Egrave;&Eacute;&Ecirc;&Euml;&Igrave;&Iacute;&Icirc;&Iuml;&ETH;&Ntilde;&Ograve;&Oacute;&Ocirc;&Otilde;&Ouml;&times;&Oslash;&Ugrave;&Uacute;&Ucirc;&Uuml;&Yacute;&THORN;&szlig;&agrave;&aacute;&acirc;&atilde;&auml;&aring;&aelig;&ccedil;&egrave;&eacute;&ecirc;&euml;&igrave;&iacute;&icirc;&iuml;&eth;&ntilde;&ograve;&oacute;&ocirc;&otilde;&ouml;&divide;&oslash;&ugrave;&uacute;&ucirc;&uuml;&yacute;&thorn;&yuml;&OElig;&oelig;&Scaron;&scaron;&Yuml;&fnof;&circ;&tilde;&Alpha;&Beta;&Gamma;&Delta;&Epsilon;&Zeta;&Eta;&Theta;&Iota;&Kappa;&Lambda;&Mu;&Nu;&Xi;&Omicron;&Pi;&Rho;&Sigma;&Tau;&Upsilon;&Phi;&Chi;&Psi;&Omega;&alpha;&beta;&gamma;&delta;&epsilon;&zeta;&eta;&theta;&iota;&kappa;&lambda;&mu;&nu;&xi;&omicron;&pi;&rho;&sigmaf;&sigma;&tau;&upsilon;&phi;&chi;&psi;&omega;&thetasym;&upsih;&piv;&ensp;&emsp;&thinsp;&zwnj;&zwj;&lrm;&rlm;&ndash;&mdash;&lsquo;&rsquo;&sbquo;&ldquo;&rdquo;&bdquo;&dagger;&Dagger;&bull;&hellip;&permil;&prime;&Prime;&lsaquo;&rsaquo;&oline;&frasl;&euro;&image;&weierp;&real;&trade;&alefsym;&larr;&uarr;&rarr;&darr;&harr;&crarr;&lArr;&uArr;&rArr;&dArr;&hArr;&forall;&part;&exist;&empty;&nabla;&isin;&notin;&ni;&prod;&sum;&minus;&lowast;&radic;&prop;&infin;&ang;&and;&or;&cap;&cup;&int;&there4;&sim;&cong;&asymp;&ne;&equiv;&le;&ge;&sub;&sup;&nsub;&sube;&supe;&oplus;&otimes;&perp;&sdot;&lceil;&rceil;&lfloor;&rfloor;&lang;&rang;&loz;&spades;&clubs;&hearts;&diams;';

@Injectable({
  providedIn: 'root'
})
export class FroalaHelper {
  private _emailButtons = [
    ['bold', 'italic', 'underline', 'insertLink'],
    ['fontFamily', 'fontSize', 'textColor'],
    ['paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent'],
    ['clearFormatting'],
    ['html']
  ];

  private _buttons = [
    ['bold', 'italic', 'underline', 'strikeThrough'],
    ['insertLink', 'specialCharacters'],
    ['paragraphFormat', 'fontFamily', 'fontSize', 'textColor'],
    ['alignLeft', 'alignCenter', 'alignRight'],
    ['formatOL', 'formatUL', 'outdent', 'indent'],
    ['clearFormatting'],
    ['spellChecker'],
    ['undo', 'redo', 'html']
  ];

  private _minimalButtons = [
    ['bold', 'italic', 'underline', 'insertLink'],
    ['fontSize', 'textColor'],
    ['align', 'formatOL', 'formatUL', 'outdent', 'indent'],
    ['clearFormatting']
  ];

  private rtdFont: object = {
    "'Lora','Times New Roman', Georgia, serif": 'Times New Roman',
    "'Nunito Sans', Arial, Helvetica, sans-serif": 'Arial'
  };

  content = {
    ...FroalaHelper.basicOptions(this._buttons),
    entities,
    placeholderText: '',
    toolbarBottom: true
  };

  email = {
    ...FroalaHelper.basicOptions(this._emailButtons),
    height: 250,
    placeholderText: 'Message to Investors',
    toolbarBottom: true
  };

  messageTemplate = {
    ...FroalaHelper.basicOptions(this._emailButtons),
    height: 250,
    placeholderText: 'Provide language which will be included in your capital call notices.',
    toolbarBottom: true
  };

  entityEditor = {
    ...FroalaHelper.basicOptions(this._buttons),
    height: 200,
    placeholderText: '',
    toolbarBottom: true
  };

  formElement = {
    ...FroalaHelper.basicOptions(this._minimalButtons),
    height: 200,
    placeholderText: '',
    toolbarBottom: true
  };

  rtd = {
    ...FroalaHelper.basicOptions(this._buttons),
    entities,
    fontFamily: this.rtdFont
  };

  shareWizardContent = {
    ...FroalaHelper.basicOptions(this._emailButtons),
    height: 200,
    placeholderText: 'Optional Message',
    toolbarBottom: true
  };

  addMember = {
    ...FroalaHelper.basicOptions(this._emailButtons),
    height: 120,
    placeholderText: 'Enter Message',
    toolbarBottom: true
  };

  emailProspect = {
    ...FroalaHelper.basicOptions(this._emailButtons),
    height: 250,
    placeholderText: 'Message to prospects',
    toolbarBottom: true
  };

  constructor() {}

  private static basicOptions(buttons) {
    return {
      attribution: false,
      charCounterCount: false,
      htmlAllowedTags: ['.*'],
      htmlRemoveTags: ['script'],
      htmlUntouched: true,
      key: environment.FROALA_KEY,
      placeholderText: '',
      quickInsertTags: [''],
      toolbarButtons: buttons,
      toolbarButtonsMD: buttons,
      toolbarButtonsSM: buttons,
      toolbarButtonsXS: buttons,
      toolbarSticky: false
    };
  }
}
