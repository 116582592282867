/*
  Rendered Form Service

  This service contains form validation, form groups, and any components needed
  to build a reactive form
*/
import { Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Liquid } from 'liquidjs';

import { environment } from '@env/environment';
import { FormElement, FormSection } from '@state/form';
import { parseDate, shortDate } from '@helpers/date';

import { BaseService } from '../../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RenderedFormService extends BaseService {
  // HACKETY HACK HACK HACK!
  // TODO: Move necessary global objects and functions into akita stores, reduce necesasry global logic
  // so that any mutations only occur as necessary through akita service

  _dynamicModels: any = {};
  activeSectionIndex = 0;
  dynamicDocuments: any = {};
  dynamicDocumentTitles: any = {}; // TODO: need to refactor this to make a model
  error: any = undefined;
  form: any;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  isSubmitting = false;
  liquidEngine: Liquid;
  user: any;

  constructor(private http: HttpClient) {
    super();
    this.liquidEngine = new Liquid();
    this.liquidEngine.registerFilter('update_variable', (_initial, variable, value) => {
      // initial value should be blank
      if (value === 'null') value = null;
      this.dynamicModels[variable] = value;
    });
    this.liquidEngine.registerFilter('shortDate', (date: Date): string => parseDate(date, shortDate));
  }

  get dynamicModels(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._dynamicModels;
  }

  set dynamicModels(value: object) {
    if (value['CURRENT_DATE']) {
      value['CURRENT_DATE'] = new Date(value['CURRENT_DATE']);
    }

    // Convert true/false values to string
    Object.keys(value).forEach((key) => {
      if (value[key] === false) value[key] = 'false';
      if (value[key] === true) value[key] = 'true';
    });

    this._dynamicModels = value;
  }

  get visibleSections(): any[] {
    if (!this.form) return [];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.sections.filter((section) => this.objectIsVisible(section));
  }

  objectIsVisible(visibilityObject: FormElement | FormSection): boolean {
    let { visibility_key, visibility_values } = visibilityObject;

    if (visibility_key) {
      if (visibility_values.length) {
        // This is a special value for visibility
        let nullValueIndex = visibility_values.indexOf('**null**');
        if (nullValueIndex > -1) {
          visibility_values[nullValueIndex] = '';
        }

        let visibilityKey = this.valueToString(this.dynamicModels[visibility_key]);
        return visibility_values.indexOf(visibilityKey) > -1;
      } else {
        return !this.dynamicModels[visibility_key];
      }
    } else {
      return true;
    }
  }

  // Old way (wrong way) of casting value to string was to check for
  // this.dynamicModels[visibility_key] as a truthy
  // instead of checking if it was undefined
  //
  // I.e. (this.dynamicModels[visibility_key] || '').toString()
  // This meant that any values that were booleans and false were returning as ''
  // which meant that they were never
  // correctly conditionally showing
  valueToString(value: any): string {
    if (value === undefined || value === null) return '';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value.toString();
  }

  reset(): void {
    this.activeSectionIndex = 0;
  }

  updateFormValues(
    variablesObject: any,
    requestToken: string,
    formId: ID,
    assignableId: ID,
    callbacks?: any
  ): Subscription {
    const form_values = variablesObject;
    const assignable_id = assignableId;
    const url = `${environment.apiUrl}/signature_requests/${requestToken}/form/${formId}/update_values`;

    this.error = undefined;
    return this.http.post(url, { assignable_id, form_values }).subscribe({
      error: (error) => {
        this.error = 'Unable to process';

        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }
}
