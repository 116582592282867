import { Injectable } from '@angular/core';
import Hotjar from '@hotjar/browser';

import { environment } from '@env/environment';
import { ID, UUID } from '@state/base';

// This is copy-pasted from the types in Hotjar, but Hotjar itself does not export it
export type UserInfo = Record<string | number, string | number | Date | boolean>;

@Injectable({ providedIn: 'root' })
export class HotjarService {
  hotjarSiteId = parseInt(environment.HOTJAR_SITE_ID, 10);
  hotjarVersion = 6;
  hotjarEnabled = !!this.hotjarSiteId;

  constructor() {
    let { hotjarEnabled, hotjarSiteId, hotjarVersion } = this;
    if (hotjarEnabled) Hotjar.init(hotjarSiteId, hotjarVersion);
  }

  event(eventName: string): void {
    if (this.hotjarEnabled) Hotjar.event(eventName);
  }

  identify(userId: ID, userInfo: UserInfo): void {
    if (this.hotjarEnabled) Hotjar.identify((userId as UUID), userInfo);
  }
}
