import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

export enum ControlStyle {
  Transparent = 'transparent',
  Warning = 'warning'
}

export interface Control {
  click: () => void;
  label: string;
  style?: ControlStyle;
}

export interface FormLayoutProps {
  controls?: Control[];
  title?: string;
}

const initialState: FormLayoutProps = {
  controls: [],
  title: undefined
};

@Injectable({ providedIn: 'root' })
export class FormLayoutRepository {
  _store$ = createStore({ name: 'form-layout' }, withProps<FormLayoutProps>(initialState));

  controls$ = this._store$.pipe(select(({ controls }) => controls));
  title$ = this._store$.pipe(select(({ title }) => title));

  setAllAttributes({ controls, title }: FormLayoutProps): void {
    this._store$.update(setProps({ controls, title }));
  }

  setControls(controls: Control[]): void {
    this._store$.update(setProp('controls', controls));
  }

  setTitle(title: string): void {
    this._store$.update(setProp('title', title));
  }

  reset(): void {
    this._store$.reset();
  }
}
