import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Entity, EntityTeam } from './entity.model';

export interface EntitiesState extends EntityState<Entity> {
  entityTeams: EntityTeam[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'funds', resettable: true })
export class EntitiesStore extends EntityStore<EntitiesState, Entity> {
  constructor() {
    super();
  }
}
