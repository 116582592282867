/*
  UIComponents
  Main UI module that manages the availability of all UI-related components
*/

// Angular Dependencies
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Shared Pipes
import { SafeHtmlPipe } from './safehtml.pipe';
import { SafeUrlPipe } from './safeUrl/safeUrl.pipe';

const pipes = [SafeHtmlPipe, SafeUrlPipe];
// Module Definition
@NgModule({
  declarations: pipes,
  exports: pipes,
  imports: [CommonModule]
})

// Class Definition
export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: []
    };
  }
}
