import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { environment } from '@env/environment';
import { toString } from '@helpers/string';

import { SignatureRequestActorInvitationState } from './signature-request.model';
import { SignatureRequestState, SignatureRequestStore } from './signature-request.store';

function visibleSections(state: SignatureRequestState) {
  let { formValues, form } = state;
  return form?.sections.filter((section) => {
    let { visibility_key, visibility_values } = section;

    if (visibility_key) {
      if (visibility_values.length) {
        // This is a special value for visibility
        let nullValueIndex = visibility_values.indexOf('**null**');
        if (nullValueIndex > -1) visibility_values[nullValueIndex] = '';

        let visibilityKey = toString(formValues[visibility_key]);
        return visibility_values.indexOf(visibilityKey) > -1;
      } else {
        return !formValues[visibility_key];
      }
    } else {
      return true;
    }
  });
}

function canShowForms(state: SignatureRequestState) {
  let { form, signatureRequest } = state;
  let { Accepted, Pending } = SignatureRequestActorInvitationState;
  let allowedStates = [Accepted, Pending];
  return form && allowedStates.includes(signatureRequest?.actor_invitation_state);
}

function currentFormId(state: SignatureRequestState) {
  let { signatureRequest, currentFormIndex } = state;
  return signatureRequest?.forms[currentFormIndex]?.id;
}

@Injectable({ providedIn: 'root' })
export class SignatureRequestQuery extends Query<SignatureRequestState> {
  canShowForms$ = this.select(canShowForms);
  currentFormId$ = this.select(currentFormId);
  fliInvestmentPage$ = this.select('signatureRequest').pipe(
    map((signatureRequest) => {
      let { fliUrl } = environment;
      if (!signatureRequest) return;
      let { investment_id, organization_details } = signatureRequest;
      let org_url_slug = organization_details?.organization?.url_slug;
      return org_url_slug && investment_id
        ? `${fliUrl}/g/${org_url_slug}/investments/${investment_id}`
        : undefined;
    })
  );
  form$ = this.select('form');
  signatureRequest$ = this.select('signatureRequest');
  submitting$ = this.select('submitting');
  wrongActor$ = this.select('wrongActor');
  visibleSections$ = this.select(visibleSections).pipe(filter((sections) => !!sections));
  constructor(protected store: SignatureRequestStore) {
    super(store);
  }
}
