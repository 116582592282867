import { createStore, select, withProps } from '@ngneat/elf';
import { filter, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { selectRequestStatus, updateRequestStatus, withRequestsStatus } from '@ngneat/elf-requests';

import { ReportState } from './report.model';

@Injectable({ providedIn: 'root' })
export class ReportRepository {
  private _store$ = createStore(
    { name: 'report' },
    withProps<ReportState>({}),
    withRequestsStatus<'report'>()
  );

  availableReports$ = this._store$.pipe(
    filter((state) => !!state.available_reports),
    select((state) => state.available_reports)
  );
  loading$ = this._store$.pipe(
    selectRequestStatus('report'),
    map((status) => status.value !== 'success')
  );
  reportUrl$ = this._store$.pipe(select((state) => state.reportUrl));
  summaryStats$ = this._store$.pipe(select((state) => [state.summary_stats]));

  getValue(): ReportState {
    return this._store$.getValue();
  }

  updateStore(reportData: ReportState): void {
    this._store$.update(
      (state) => ({
        ...state,
        ...reportData
      }),
      updateRequestStatus('report', 'success')
    );
  }
}
