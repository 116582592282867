import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { filter } from 'rxjs/operators';

import { Document, DocumentPreviewType } from './document.model';

interface DocumentProps {
  document?: Document;
}

const initialState: DocumentProps = {
  document: undefined
};

@Injectable({ providedIn: 'root' })
export class DocumentRepository {
  private _store$ = createStore({ name: 'document' }, withProps<DocumentProps>(initialState));

  document$ = this._store$.pipe(select(({ document }) => document));
  documentPreviewType$ = this._store$.pipe(
    filter(({ document }) => !!document),
    select(({ document }) => {
      let { preview, page_count, pages, title } = document;
      // The presence of a page count, implies it is a pdf document ready to render
      if (page_count && pages.length) return DocumentPreviewType.Document;

      // Preview is always preview
      if (preview) return DocumentPreviewType.HtmlPreview;

      // If there is no page count, at this point and the file is pdf, this means the file is still processing
      // (or has failed to process)
      if (/.*\.pdf$/.test(title)) return DocumentPreviewType.Processing;

      // All other types are downloadable
      return DocumentPreviewType.Download;
    })
  );

  getValue(): DocumentProps {
    return this._store$.getValue();
  }

  reset(): void {
    this._store$.reset();
  }

  setDocument(document: Document): void {
    this._store$.update((state) => ({ ...state, document }));
  }
}
