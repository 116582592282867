import { NgModule, ModuleWithProviders, Type } from '@angular/core';

import { ZendeskConfig } from './zendesk.model';
import { ZendeskService } from './zendesk.service';

@NgModule({})
export class ZendeskModule {
  static forRoot(
    zendeskConfig: Type<ZendeskConfig>
  ): ModuleWithProviders<ZendeskModule> {
    return {
      ngModule: ZendeskModule,
      providers: [
        { provide: ZendeskConfig, useClass: zendeskConfig },
        {
          deps: [ZendeskConfig],
          provide: ZendeskService,
          useClass: ZendeskService
        }
      ]
    };
  }
}
