import { ID } from '@datorama/akita';

import { BaseModel } from '@state/base';
import { EntityMember } from '@state/entities';
import { PortfolioCompany } from '@state/investments';
import { ThinTask } from '@state/task';

export enum InvestmentStateTypes {
  Archived = 'archived',
  Complete = 'complete',
  Declined = 'declined',
  InProgress = 'in_progress',
  Invited = 'invited',
  Prospect = 'prospect',
  UnderReview = 'under_review',
  Viewed = 'viewed'
}

export interface Investment {
  amount_condensed_formatted: string;
  amount_formatted: string;
  carry_percent_formatted?: string;
  commitment_amount_formatted: string;
  contributed_amount_formatted?: string;
  created_at: string;
  created_at_formatted: string;
  id: ID;
  investment_account_id?: string;
  investment_account: InvestmentAccount;
  investment_allocation_formatted?: string;
  management_fee_amount_formatted?: string;
  management_fee_percent_formatted?: string;
  next_task_id: ID;
  next_task_title: string;
  portfolio_company: PortfolioCompany;
  state: InvestorState;
  status: string;
  subscription_amount_cents?: number;
  total_due_formatted: string;
  total_received_formatted?: string;
  unfunded_commitment_amount_formatted?: string;
  updated_at: string;
  updated_at_formatted: string;
  vehicle: Vehicle;
  workflow?: Workflow;
}

// InvestmentState::InProgress
export enum InvestmentSubstateLabel {
  Committed = 'investment_committed',
  Contributed = 'investment_contributed',
  Countersigned = 'investment_countersigned',
  PendingUpdate = 'investment_pending_update',
  SelectedInvestmentProfile = 'selected_investment_profile',
  Signed = 'investment_signed'
}

export const investmentStateLabels = {
  archived: 'Archived',
  comments_pending_approval: 'Pending Approval',
  comments_pending_release: 'Pending Release',
  complete: 'Completed', // BE manually changes 'completed' to complete. Leaving here for backwards compatability
  completed: 'Completed',
  declined: 'declined',
  in_progress: 'In Progress',
  investment_approved: 'LP Approved',
  investment_committed: 'Commited',
  investment_contributed: 'Contributed',
  investment_countersigned: 'Countersigned',
  investment_pending_review: 'Pending Review',
  investment_pending_update: 'Pending LP Update',
  investment_signed: 'Investment Signed',
  invitation_bounced: 'bounced',
  invitation_delivered: 'delivered',
  invitation_opened: 'opened',
  invitation_sent: 'sent',
  invited: 'Investor Invited',
  prospect: 'Prospect',
  selected_investment_profile: 'Profiled Selected',
  under_review: 'Under Review',
  viewed: 'viewed'
};

export interface InvestmentSubState extends BaseModel {
  active: boolean;
  archived_at?: string;
  end_at?: string;
  end_at_date?: string;
  investment_state_id: ID;
  label: InvestmentSubstateLabel | string;
  updated_at_date_time: string;
}

export interface Vehicle {
  allow_cap_call_events: boolean;
  created_at_formatted: string;
  created_at: string;
  execute_by: string;
  id: ID;
  is_managed: boolean;
  legal_name: string;
  logo: string;
  minimum_investment: number;
  name: string;
  organization_id: ID;
  organization_logo?: string;
  organization_name: string;
  organization_url_slug?: string;
  portfolio_company_legal_name: string;
  round_type: string;
  security?: string;
  size: number;
  summary?: string;
  type: string;
  updated_at_formatted: string;
  updated_at: string;
}

export interface Workflow extends BaseModel {
  description: string;
  message: string;
  notes?: string;
  started_at?: string;
  state: 'unstarted';
  tasks: ThinTask[];
  title: string;
}

export interface InvestmentAccount extends BaseModel {
  display_name: string;
  entity_id: ID;
  entity_subtype_formatted?: string;
  entity_type_formatted?: string;
  legal_name: string;
  members: InvestmentAccountMember[];
  trust_subtype_formatted?: string;
  trust_type_formatted?: string;
  type_display_label: string;
  type_formatted?: string;
}

export interface InvestmentAccountMember extends BaseModel {
  initials: string;
  name: string;
}

export interface InvestorState {
  active_substate?: InvestmentSubState;
  created_at: string;
  created_at_formatted: string;
  created_at_millis: number;
  end_at?: string;
  id: ID;
  investment_id: ID;
  investment_substates?: InvestmentSubState[];
  start_at: string;
  type: InvestmentStateTypes;
  updated_at: string;
  updated_at_formatted: string;
  updated_at_millis: number;
}

export interface InvestmentState {
  investment?: Investment;
  loading: boolean;
  members: EntityMember[];
  mobileAsideVisible: boolean;
}
