import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Entity, EntityParams, EntityTeam } from '@state/entities';

import {
  AddEntityRelationshipParams,
  FeatureConfiguration,
  FeatureConfigurationParams,
  FeatureConfigurationUserPermissions,
  FeatureRoleConfigurationParams,
  MessageTemplate
} from './entity.model';
import { EntityRepository } from './entity.repository';

export interface AddEntityRelationshipResponseBody {
  id: ID;
}

@Injectable({ providedIn: 'root' })
export class EntityService {
  constructor(private http: HttpClient, private entityRepository: EntityRepository) {}

  addEntityRelationship$(
    entity_id: ID,
    data: AddEntityRelationshipParams
  ): Observable<AddEntityRelationshipResponseBody> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/entity_relationships`;
    return this.http.post<AddEntityRelationshipResponseBody>(url, data);
  }

  archive$(entity_id: ID): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/archive`;
    return this.http.post<null>(url, {});
  }

  clearTeams(): void {
    this.entityRepository.clearTeams();
  }

  get(id: ID): void {
    this.get$(id).subscribe({
      next: (entity: Entity) => {
        this.set(entity);
      }
    });
  }

  get$(id: ID, providerId?: ID): Observable<Entity> {
    let url = `${environment.apiUrl}/v1/entities/${id}`;
    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }
    return this.http.get(url, options) as Observable<Entity>;
  }

  getAndStoreEntity$(id: ID, providerId?: ID): Observable<Entity> {
    return this.get$(id, providerId).pipe(tap({ next: (entity) => this.entityRepository.setEntity(entity) }));
  }

  getMessageTemplates$(orgEntityId: ID): Observable<MessageTemplate[]> {
    let url = `${environment.apiUrl}/v1/entities/${orgEntityId}/message_templates`;
    return this.http.get(url) as Observable<MessageTemplate[]>;
  }

  getTeams$(entity_id: ID): Observable<EntityTeam[]> {
    let teamsUrl = `${environment.apiUrl}/v1/entities/${entity_id}/teams`;
    return this.http.get<EntityTeam[]>(teamsUrl);
  }

  getAndStoreTeams$(entity_id: ID): Observable<EntityTeam[]> {
    return this.getTeams$(entity_id).pipe(
      tap({ next: (entityTeams) => this.entityRepository.setTeams(entityTeams) })
    );
  }

  getFeatureConfigurations$({ entity_id }: FeatureConfigurationParams): Observable<FeatureConfiguration[]> {
    let featureConfigurationEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/feature/configurations`;
    return this.http.get<FeatureConfiguration[]>(featureConfigurationEndpoint);
  }

  getFeatureConfiguration$({
    entity_id,
    provider_id,
    type
  }: FeatureConfigurationParams): Observable<FeatureConfigurationUserPermissions> {
    let featureConfigurationEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/feature/configurations/${type}`;
    let options = {};

    if (provider_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_id });
      options = { headers };
    }

    return this.http.get<FeatureConfigurationUserPermissions>(featureConfigurationEndpoint, options);
  }

  getAndStoreFeatureConfigurations$({
    entity_id
  }: FeatureConfigurationParams): Observable<FeatureConfiguration[]> {
    return this.getFeatureConfigurations$({ entity_id }).pipe(
      tap({
        next: (featureConfigurations) => {
          this.entityRepository.setFeatureConfigurations(featureConfigurations);
        }
      })
    );
  }

  reset(): void {
    this.entityRepository.reset();
  }

  removeEntityRelationship$(entityId: ID, entityRelationshipId: ID): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entityId}/entity_relationships/${entityRelationshipId}`;
    return this.http.delete<null>(url);
  }

  set(entity: Entity): void {
    this.entityRepository.setEntity(entity);
  }

  toggleFeatureConfiguration$({ enabled, entity_id, type }: FeatureConfigurationParams): Observable<void> {
    let featureConfigurationEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/feature/configurations/${type}`;
    return this.http.patch<void>(featureConfigurationEndpoint, { enabled });
  }

  toggleFeatureRoleConfiguration$({
    role_action,
    enabled,
    entity_id,
    role,
    type
  }: FeatureRoleConfigurationParams): Observable<void> {
    let featureConfigurationEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/feature/configurations/${type}/role_configurations/${role}`;
    return this.http.patch<void>(featureConfigurationEndpoint, { enabled, role_action });
  }

  updateEntity$(params: Partial<EntityParams>, id: ID, providerId?: ID): Observable<Entity> {
    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }
    return this.http.put<Entity>(`${environment.apiUrl}/v1/entities/${id}`, params, options);
  }

  updateAndStoreEntity$(params: Partial<EntityParams>, id: ID, providerId?: ID): Observable<any> {
    return this.updateEntity$(params, id, providerId).pipe(
      take(1),
      tap({
        next: (entity: Entity) => {
          if (entity) this.set(entity);
        }
      })
    );
  }
}
