import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  accountLocked: boolean;
  hasResetPassword: boolean;
  loginFormAttempts: number;
  loginFormError?: string;
  loginFormSubmitted: boolean;
  loginSuccess: boolean;
  mfa_token_view: boolean;
  redirectUrl?: string;
  requiresTokenVerification?: boolean;
}

function createInitialState(): SessionState {
  return {
    accountLocked: false,
    hasResetPassword: false,
    loginFormAttempts: 0,
    loginFormSubmitted: false,
    loginSuccess: false,
    mfa_token_view: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}
