import { Injectable } from '@angular/core';

import { PageObject } from '@state/document';

@Injectable({
  providedIn: 'root'
})
export class EditorState {
  selectedItem: any = {};
  selectedItemPageObject: PageObject;

  constructor() {}
}
