import { BaseModel, ID } from '@state/base';
import { Page } from '@state/document';
import { SelectableType } from '@state/form';

export enum DocumentFieldDeletionStatus {
  NotDeleting = 'not_deleting',
  Deleting = 'deleting',
  Failed = 'failed'
}

export type DocumentFieldDeletionState = [status: DocumentFieldDeletionStatus, message: string];

export interface DocumentVersion extends BaseModel {
  filename?: string;
  page_count: number;
}

export interface FormBuilderDocument extends BaseModel {
  allow_download?: boolean;
  archived: boolean;
  auth_token: ID;
  available_for_download: boolean;
  bundle?: any;
  bundled_documents: any[];
  can_counter_sign: boolean;
  comes_from_rtd?: boolean;
  counter_signable_fields_count: number;
  counter_signed: boolean;
  created_at_epoch: number;
  current_version_id: ID;
  document_versions: DocumentVersion[];
  done_actors?: any;
  extension: string;
  form_id?: ID;
  investment_account_name?: string;
  investment_name?: string;
  notes?: any;
  organization: {
    entity_id: ID;
    name: string;
  };
  organization_id: ID;
  owner: {
    allocation_amount?: number;
    allocation_amount_formatted: string;
    id: ID;
    investment_email: string;
    investment_owner_name: string;
    owner_label: string;
    owner_sublabel: string;
    total_due: number;
    total_due_formatted: string;
    type: string; // TODO: Create an enum for types here
  };
  page_count: number;
  page_objects: {
    [id: string]: {
      counter_signable: boolean;
      id: ID;
      page_index: number;
    };
  };
  pages: Page[];
  processed_pages_count: number;
  // This is optional because it does not exist in data and is only set in the interface
  // for easy tracking as the element is moved through the system –
  // this should be dealt with either at a repository level or some state that
  // does not pollute the repository object
  selectableType?: SelectableType.Document;
  shared_at_formatted: string;
  shared_document?: any;
  signable_fields_count: number;
  signed: boolean;
  source_url: string;
  state: string;
  tags_hash?: any;
  tags: string[];
  template: boolean;
  thumbnail: string;
  title: string;
  type_humanized: string;
  type?: string;
  updated_at_epoch: number;
  url: string;
  vehicle?: {
    id: ID;
    is_template: boolean;
    name: string;
    type: string; // TODO: Create enum for types
  };
  vehicle_id?: ID;
  vehicle_visibility?: boolean;
}
