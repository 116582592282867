/*
  Base Interceptor
  A base interceptor from which other interceptors should inherit
*/

import { catchError, filter } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { getErrorMessage } from '@helpers/error';
import { SessionService } from '@state/session';

export interface FlowHttpErrorResponse extends HttpErrorResponse {
  handled?: boolean;
}

// Injectable Definition
@Injectable({
  providedIn: 'root'
})
export class BaseInterceptor implements HttpInterceptor {
  constructor(private router: Router, private sessionService: SessionService, private snackBar: MatSnackBar) {
    this.dismissSnackBarOnTransition();
  }

  get providerEntityIdInRoute(): string {
    let match = /(\/p\/)([0-9a-f\-)]+)/.exec(this.router.url);
    return match && match[2];
  }

  dismissSnackBarOnTransition(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe({
      next: () => {
        // eslint-disable-next-line no-underscore-dangle
        let snackBarRef = this.snackBar._openedSnackBarRef;
        if (snackBarRef && snackBarRef.containerInstance.snackBarConfig.panelClass === 'snack-error') {
          this.snackBar.dismiss();
        }
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.providerEntityIdInRoute) {
      request = request.clone({
        setHeaders: { 'Provider-ID': this.providerEntityIdInRoute }
      });
    }

    return next.handle(request).pipe(
      catchError((err: FlowHttpErrorResponse): Observable<never> => {
        let message: string;

        // Handle errors at the route level
        switch (err.status) {
          case 400:
          case 401: // Unauthorized error
          case 422: // Used for "record exists" and "unprocessable entity"
            message = getErrorMessage(err);
            break;
          case 403: // Forbidden code used to prevent snackbar for locked accounts
          case 404: // Not found (needed to prevent snackbar messages)
            break;
          case 498:
            this.sessionService.logout({ message: 'Your token has expired, please login again' });
            break;
          default:
            // Unknown error
            message =
              'Looks like something went wrong. Please try again. If problem ' +
              'persists please contact support@flowinc.com';
            break;
        }

        if (message) {
          err.handled = true;
          this.snackBar.open(message, '', { panelClass: 'snack-error' });
        }

        return throwError(() => err);
      })
    );
  }
}
