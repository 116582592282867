import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { map, Observable } from 'rxjs';

import { MembershipsState, MembershipsStore } from './memberships.store';
import { Membership } from './membership.model';

@Injectable({ providedIn: 'root' })
export class MembershipsQuery extends QueryEntity<MembershipsState, Membership> {
  entityId$ = this.select('membership').pipe(map((membership) => membership.entity_id));
  isLoggedAsProvider$ = this.select('membership').pipe(
    map((membership) => Object.keys(membership?.provider_context ?? {}).length !== 0)
  );
  nonProviderOrganizationMemberships$ = this.selectAll({
    filterBy: ({ provider }) => !provider
  });
  providerOrganizationMemberships$ = this.selectAll({
    filterBy: ({ provider }) => provider
  });

  constructor(protected store: MembershipsStore) {
    super(store);
  }

  get entityId(): ID {
    return this.membership?.entity_id;
  }

  get entityIdAsProviderId$(): Observable<ID> {
    return this.select('membership').pipe(map((membership) => membership.entity_id));
  }

  get investmentAccountId(): ID {
    return this.membership?.investment_account_id;
  }

  get isInvestmentAccount(): boolean {
    return !!this.investmentAccountId;
  }

  get isOrganization(): boolean {
    return !!this.organizationId;
  }

  get isSaasEnabled(): boolean {
    return this.membership?.saas_enabled;
  }

  get isServiceEnabled(): boolean {
    return this.membership?.service_enabled;
  }

  get membership(): Membership {
    return this.getValue().membership;
  }

  get organizationId(): ID {
    return this.membership?.organization_id;
  }
}
