import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizationLayoutComponent } from '@app/layout/authorization-layout';

import { ResetPasswordComponent } from './reset-password.component';

const routes: Routes = [
  {
    children: [{ component: ResetPasswordComponent, path: '' }],
    component: AuthorizationLayoutComponent,
    path: ''
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class ResetPasswordRoutingModule {}
