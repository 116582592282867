/*
  Auth Service
  Auth-related operations and state management
*/

// Angular Dependencies
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthLayoutService } from '@app/state/auth-layout';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private authLayoutService: AuthLayoutService,
    private cookieService: CookieService,
    private jwtHelperService: JwtHelperService
  ) {}

  loggedIn(): boolean {
    let cookieToken = this.cookieService.get('token');
    let isExpired = this.jwtHelperService.isTokenExpired(cookieToken);

    return !isExpired;
  }

  user(): any {
    let cookieToken = this.cookieService.get('token');

    try {
      return this.jwtHelperService.decodeToken(cookieToken);
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
