import { Injectable } from '@angular/core';

import { Control, FormLayoutProps, FormLayoutRepository } from './form-layout.repository';

@Injectable({ providedIn: 'root' })
export class FormLayoutService {
  constructor(private formLayoutRepository: FormLayoutRepository) {}

  reset(): void {
    this.formLayoutRepository.reset();
  }

  setAllAttributes({ controls, title }: FormLayoutProps): void {
    this.formLayoutRepository.setAllAttributes({ controls, title });
  }

  setTitle(title: string): void {
    this.formLayoutRepository.setTitle(title);
  }

  setControls(controls?: Control[]): void {
    this.formLayoutRepository.setControls(controls);
  }
}
