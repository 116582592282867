/*
  Base Service
  A base service from which other services should inherit
*/
import { environment } from '@env/environment';

export class BaseService {
  BASE_URL: string = environment.apiUrl;
  error: any = undefined;
  isLoading = false;
  success = false;

  constructor() {}

  applyCallback(callbackName?: string, callbacks?: any, callbackData?: any): any {
    this.isLoading = false;

    if (callbacks && callbacks[callbackName]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return callbacks[callbackName](callbackData);
    }
  }
}
