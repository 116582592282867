import { enableAkitaProdMode } from '@datorama/akita';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DocumentsAppModule } from '@app/documents-app.module';
import { environment } from '@env/environment';

// Production toggles
if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

// Initialize the app
let bootstrap = () => platformBrowserDynamic().bootstrapModule(DocumentsAppModule);
bootstrap().catch((err) => console.error(err));
