import { BaseModel, ID } from '@state/base';
import { ColumnLike } from '@component/collection-table';
import { Entity, EntityMemberCommunicationType, EntityRole, PrimaryContact } from '@state/entities';
import { EntityType } from '@component/entity-form-modal';
import { Holdings } from '@component/add-holdings';
import { InvestorState } from '@state/investment';
import { OverviewCard } from '@component/overview-cards';
import { User } from '@state/user';
import { FeatureRoleConfigurationActionType } from '@state/entity';

export enum OwnershipClassName {
  EntityRelationship = 'EntityRelationship',
  Investment = 'Investment',
  Holding = 'Holding'
}

export enum OwnershipType {
  Investments = 'investments',
  Holdings = 'holdings', // eslint-disable-line @typescript-eslint/no-shadow
  Entities = 'entities'
}

export const ownershipTypeLabel = {
  [OwnershipType.Investments]: 'investors',
  [OwnershipType.Holdings]: 'holdings',
  [OwnershipType.Entities]: 'entities'
};

export enum PropertyType {
  Boolean = 'boolean',
  Currency = 'currency',
  Date = 'date',
  String = 'string',
  Integer = 'numeric'
}

type FormTitle = string;

export interface InvestorQuestionnaireData {
  completed_at?: Date;
  title: string;
  form_data: Record<FormTitle, FormFieldData[]>;
}

export interface InvestorQuestionnaireReview {
  action: ID;
  activities: QuestionnaireReviewActivity[];
}

export type InvestorQuestionnaireReviewActivityLog = QuestionnaireReviewActivity[];
export interface QuestionnaireReviewActivity extends BaseModel {
  action_type: FeatureRoleConfigurationActionType;
  author: Author;
  data: QuestionnaireReviewActivityData;
  note?: string;
}

interface Author {
  email: string;
  first_name: string;
  full_name: string;
  id: ID;
  last_name: string;
  middle_name?: string;
  name: string;
}

interface QuestionnaireReviewActivityData {
  message: string;
}

export interface FormFieldData {
  field: FormField;
  meta_hash: MetaHash;
}

export interface CustomValue {
  [custom_variable_key: string]: string;
}

interface FormField {
  label: string;
}
interface MetaHash {
  key: string;
  obfuscated: boolean;
  title?: string;
  type: string;
  value?: string;
}

export interface LegalColumn {
  cv_key: string;
  tooltip?: string;
  type: PropertyType;
  value: string;
}

export interface LegalData {
  columns: LegalColumn[];
  rows: LegalRow[];
}

export interface LegalSection {
  id: ID;
  name: string;
}

export interface LegalSectionData {
  columns: ColumnLike[];
  dataSource: LegalRow[];
}

export enum LegalStatus {
  Approved = 'approved',
  Incomplete = 'incomplete',
  ReadyForReview = 'ready_for_review',
  UpdateRequired = 'investor_update_required'
}

export interface LegalRow {
  investor: string;
  investment_id: string;
  investor_email: string;
  signature_request_state: LegalStatus;
  state_updated_at: string;
}
export interface BlueSkyRow {
  investors_count: number;
  state: string;
  total_contributed_amount: string;
}
export interface BlueSky {
  results: BlueSkyRow[];
  totals: {
    total_contributed_amount: string;
    total_investors: number;
  };
}

export interface InvestorPayload {
  carry_percent?: string;
  city?: string;
  contact_email?: string;
  contact_name?: string;
  contact_phone_number?: string;
  contact_title?: string;
  customer_code?: string;
  total_received_amount?: number;
  country?: string;
  email?: string;
  entity_subtype?: string;
  entity_type?: string;
  first_name?: string;
  investor_type?: string;
  joint_email?: string;
  joint_legal_name?: string;
  last_name?: string;
  legal_name?: string;
  line_one?: string;
  line_two?: string;
  management_fee_percent?: string;
  member_1_email?: string;
  member_1_name?: string;
  member_2_email?: string;
  member_2_name?: string;
  member_3_email?: string;
  member_3_name?: string;
  name?: string;
  ownership_units?: string;
  postal?: string;
  staged?: boolean;
  state?: string;
  subscription_amount?: number;
  tax_id?: string;
  tax_id_type?: string;
  trust_subtype?: string;
  trust_type?: string;
}

export interface InviteRequestOptions {
  bcc_emails?: string[];
  cc_emails?: string[];
  from?: string;
  investors: InvestorPayload[];
  message?: string;
  send_message: boolean;
  subject?: string;
}

export interface LPProfileDataRow extends BaseModel {
  accr_affiliated_investors?: string;
  accr_investment_company_act?: string;
  accr_investment_company_act_humanized?: string;
  accr_investor_rep_non_us_fatca: boolean;
  accredited?: boolean;
  address_city?: string;
  address_country?: string;
  address_line_one?: string;
  address_line_two?: string;
  address_postal?: string;
  address_state?: string;
  bank_account?: string;
  bank_address?: string;
  bank_beneficiary_address?: string;
  bank_name?: string;
  bank_routing?: string;
  bank_swift?: string;
  carry_percent?: string;
  carry_percent_formatted?: string;
  commitment_amount?: string;
  contact_email?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_title?: string;
  contributed_amount?: number;
  date_of_formation?: string;
  entity_subtype?: string;
  entity_type?: string;
  erisa_dol?: string;
  erisa_dol_humanized?: string;
  formation_country?: string;
  formation_state?: string;
  investor_email?: string;
  investor_name?: string;
  is_domestic?: boolean;
  is_us_person?: boolean;
  joint_email?: string;
  joint_legal_name?: string;
  legal_name?: string;
  management_fee_percent?: string;
  management_fee_percent_formatted?: string;
  qualified_client?: boolean;
  qualified_purchaser?: boolean;
  qualified_purchaser_2?: boolean;
  subscription_amount?: string;
  tax_id?: string;
  tax_id_type?: string;
  total_received?: string;
  trust_subtype?: string;
  trust_type?: string;
  unfunded_commitment_amount?: string;
  wire_beneficiary?: string;
  wire_instructions?: string;
}

export interface LPProfileData {
  results: LPProfileDataRow[];
}

export interface OwnershipOption {
  class_name: OwnershipClassName;
  id: ID;
  meta: string;
  value: string;
}

export interface CSVExportResponse extends BaseModel {
  thumbnail?: string;
  title: string;
}

export interface Fund extends BaseModel {
  allow_cap_call_events: boolean;
  allow_lawyers_review: boolean;
  calculation_method: string;
  capital_due_date?: string;
  context: {
    id: ID;
    logo?: string;
    name: string;
    organization_entity_id: ID;
  };
  entity_id: ID;
  execute_by?: string;
  has_subscription_agreement?: boolean;
  investment_count: number;
  investors_locked: boolean;
  is_managed?: boolean;
  legal_name: string;
  logo: string;
  manual_accounting: boolean;
  minimum_investment: number;
  name: string;
  organization_id: ID;
  organization_logo: string;
  organization_name: string;
  ownership_units_enabled: boolean;
  portfolio_company_legal_name: string;
  round_type: string;
  security: string;
  size: number;
  summary?: string;
  total_contributed: number;
  total_contributed_formatted: string;
  total_invested: number;
  total_invested_formatted?: string;
  totals: {
    target_size: string;
    total_allocations: string;
    total_commitments: string;
    total_contributed: string;
    total_invited: number;
  };
  type: string;
  vehicle_id?: ID;
  vintage_year?: number;
}

export interface FundBankAccount {
  available_balance_formatted: string;
  bank_name: string;
  current_balance_formatted: string;
  id: ID;
  last_checked_at: string;
  last_checked_at_formatted: string;
  last_error_at: string;
  last_error_message: string;
  mask: string;
  name: string;
  total_transactions: number;
}

export interface FundInvestmentAccount {
  accr_affiliated_investors?: string;
  accr_assets_options?: string;
  accr_company_type?: string;
  accr_erisa_dol_1: boolean;
  accr_erisa_dol_2: boolean;
  accr_erisa_dol_3: boolean;
  accr_erisa_dol_4: boolean;
  accr_investment_company_act?: string;
  accr_investment_company_reps?: string;
  accr_investment_rep_1?: string;
  accr_investment_rep_2?: string;
  accr_investment_rep_3?: string;
  accr_investment_rep_4?: string;
  accr_investment_rep_5?: string;
  accr_investor_rep_non_us_fatca: boolean;
  accr_investor_rep_non_us_w8: boolean;
  accr_investor_rep_us_person: boolean;
  accr_trust_type?: string;
  accr_type_business: boolean;
  accr_type_income: boolean;
  accr_type_networth: boolean;
  accr_written_disclosure?: string;
  accredited: boolean;
  archived: boolean;
  created_at: string;
  created_by_user_id?: string;
  electronic_records_ok?: string;
  entity_id: ID;
  erisa_dol: string;
  fax_number: string;
  formation_certificate_document_id?: string;
  id: ID;
  investor_id?: string;
  investment_type_humanized?: string;
  invitation_id?: string;
  is_us_person: boolean;
  joint_dob?: string;
  joint_email?: string;
  joint_legal_name?: string;
  joint_tax_id_encrypted_field_id?: string;
  operating_agreement_document_id?: string;
  phone_number: string;
  qualified_client: boolean;
  qualified_purchaser: boolean;
  qualified_purchaser_2?: boolean;
  token: string;
  total_invested?: string;
  total_investments?: string;
  trust_agreement_document_id?: string;
  updated_at: string;
  verified_at?: string;
}

export interface Activity {
  action: string;
  created_at_in_words: string;
  id: ID;
  initials: string;
}

export interface Action {
  cta: string;
  type: 'api' | 'application';
  url: string;
}

export interface Actionable {
  label: string;
  logo?: string;
}

export interface TaskActor {
  initials: string;
  name: string;
}

export interface Task {
  action: Action;
  actionable: Actionable;
  actor: TaskActor;
  message: string;
}

export interface Summary {
  id: ID;
  tasks: Task[];
  activities: Activity[];
  committed: string;
  contributed: string;
  target: string;
  target_size: string;
  total_committed: string;
  total_contributed?: string;
  total_contributed_formatted_short?: string;
  total_contributed_formatted?: string;
  total_life_distributions?: string;
  total_net_asset_value?: string;
  total_unfunded_commitment_amount?: string;
}

export interface InvestorTask extends BaseModel {
  title?: string;
  completed: boolean;
  message: string;
}

export interface Investor {
  available_subscription_agreement_roles: string[];
  id: ID;
  carry_percent?: string;
  carry_percent_formatted?: string;
  contributed_amount?: string;
  contributed_amount_formatted?: string;
  contributed_at?: string;
  contributed_at_formatted?: string;
  created_at?: string;
  created_at_formatted?: string;
  created_at_millis?: number;
  display_name: string;
  investment_account?: FundInvestmentAccount;
  investor_email: string;
  investor_name: string;
  invitation_sent_at?: string;
  invitation_token: string;
  legal_name?: string;
  life_distributions?: string;
  management_fee_percent?: string;
  management_fee_percent_formatted?: string;
  net_asset_value?: string;
  ownership_percent_formatted?: string;
  ownership_units_formatted?: string;
  primary_contact?: PrimaryContact;
  side_letter: boolean;
  state: InvestorState;
  tasks: InvestorTask[];
  total_received?: string;
  total_received_formatted?: string;
  total_due?: string;
  total_due_formatted?: string;
  type?: string;
  unfunded_commitment_amount?: number;
  unfunded_commitment_amount_formatted?: string;
  updated_at?: string;
  updated_at_formatted?: string;
  updated_at_millis?: number;
  wired?: boolean;
  workflow_complete?: boolean;
  workflow_started: boolean;
}

export interface CarryRecipient extends BaseModel {
  display_name: string;
  entity_relationship_id: string;
  formation_date: string;
  legal_name: string;
  notes: string;
  roles: string[];
  tax_id: string;
  type: string;
  type_formatted: string;
  vehicle_id: ID;
}

export interface ManagementFeeRecipient extends BaseModel {
  display_name: string;
  entity_relationship_id: ID;
  formation_date: string;
  legal_name: string;
  notes: string;
  roles: string[];
  tax_id: ID;
  type: string;
  type_formatted: string;
  vehicle_id: ID;
}

export interface Details extends BaseModel {
  accounting_method_humanized: string;
  arbitration_location: string;
  audit_required: boolean;
  auditor_email: string;
  auditor_name: string;
  auditor_phone: string;
  capital_due_date?: string;
  carried_interest: string;
  carried_interest_formatted: string;
  carry_percent: string;
  carry_recipient: CarryRecipient;
  close_on: string;
  complex_carry: string;
  complex_carry_details: string;
  complex_carry_humanized: string;
  entity: Entity;
  execute_by: string;
  expense_reserve: number;
  expense_reserve_formatted: string;
  external_auditor: boolean;
  fee: string;
  general_partners?: Partner[];
  form_id_cik: string;
  id: ID;
  investment?: string;
  is_managed?: boolean;
  management_companies: Partner[];
  management_fee: string;
  management_fee_formatted: string;
  management_fee_percent: string;
  management_fee_recipient: ManagementFeeRecipient;
  management_fee_term: string;
  minimum_investment: number;
  minimum_investment_formatted: string;
  ownership_units_enabled: boolean;
  portfolio_company_logo: string;
  registered_agent: string;
  reporting_frequency: string;
  reporting_frequency_humanized: string;
  size: number;
  summary: string;
  target_formatted: string;
  total_fund_contribution_formatted: string;
  vintage_year?: number;
}

export interface Holding {
  company_address: string;
  company_contact_email: string;
  company_contact_fax: string;
  company_contact_name: string;
  company_contact_phone: string;
  company_contact_title: string;
  company_date_of_formation: string;
  company_ein: string;
  company_entity_type: string;
  company_formation_country: string;
  company_logo: string;
  company_name: string;
  created_at: string;
  created_at_formatted: string;
  holding_security: string;
  id: ID;
  post_valuation_formatted: string;
  security_share_price: string;
  security_share_price_formatted: string;
  security_share_total_formatted: string;
  size: number;
  size_formatted: string;
  summary: string;
  total_investment_formatted: string;
  updated_at: string;
  updated_at_formatted: string;
  updates: any[];
}

export interface Partner {
  created_at_formatted: string;
  created_at_millis: number;
  created_at: string;
  display_name?: string;
  entity_relationship_id: string;
  formation_date?: string;
  id: ID;
  legal_name: string;
  notes: string;
  roles: EntityRole[];
  tax_id?: string;
  type_formatted: string;
  type: EntityType;
  updated_at_formatted: string;
  updated_at_millis: number;
  updated_at: string;
  vehicle_id: ID;
}

export interface FundNotification {
  action: string;
  label: string;
  subscribed: boolean;
}

export interface FundNotifications {
  columnSettings: ColumnLike[];
  dataSource: FundNotification[];
}

export interface FundState {
  activeLegalStatusTags?: LegalStatus[];
  details: Details;
  fund?: Fund;
  holding: Holding;
  holdings?: Holdings[];
  investorFormData?: InvestorQuestionnaireData[];
  investorDataReview?: InvestorQuestionnaireReview;
  investors: Investor[];
  legalData?: LegalData;
  legalSections?: LegalSection[];
  loading: boolean;
  notifications?: FundNotification[];
  overviewInvestments: OverviewCard[][];
  selectedLegalSection?: LegalSection;
  summary: Summary;
}
export interface QuestionnaireReviewMessage {
  bcc_emails?: string[];
  cc_emails?: string[];
  from?: string;
  message: string;
  reply_to?: string;
  subject: string;
  communication_types: EntityMemberCommunicationType[];
}
