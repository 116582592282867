import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';

interface AuthLayoutProps {
  message?: string;
}

const initialState: AuthLayoutProps = {
  message: undefined
};

@Injectable({ providedIn: 'root' })
export class AuthLayoutRepository {
  private _store$ = createStore({ name: 'auth-layout' }, withProps<AuthLayoutProps>(initialState));

  authMessage$ = this._store$.pipe(select(({ message }) => message));
  authMessageAsArray$ = this._store$.pipe(select(({ message }) => [message]));

  reset(): void {
    this._store$.reset();
  }

  setMessage(message: string): void {
    this._store$.update((state) => ({ ...state, message }));
  }
}
