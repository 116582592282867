/*
  Builder
  The interactive workspace of a form builder
*/
import { Component, HostListener, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { VariableService } from '@app/shared/services/variable.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-variable-create-dialog',
  styleUrls: ['variable-create.dialog.scss'],
  templateUrl: 'variable-create.dialog.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class VariableCreateDialog {
  error: any;

  variableToCreate: any = {
    description: null,
    key: null,
    obfuscated: false,
    type: null
  };

  @HostListener('window:keyup.esc')
  onKeyUp(): void {
    this.dialogRef.close(false);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VariableCreateDialog>,
    public variableService: VariableService
  ) {
    this.variableToCreate.key = data.key;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createCustomVariable(): void {
    this.error = null;
    this.variableService.createVariable(this.variableToCreate, {
      error: (error) => {
        this.error = error.error.message;
      },
      success: (data) => {
        this.dialogRef.close(data);
      }
    });
  }
}
