import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BlankLayoutComponent } from '@app/layout/blank-layout';
import { FourOhFourComponent } from './four-oh-four.component';


const routes: Routes = [
  {
    children: [
      {
        component: FourOhFourComponent,
        path: ''
      }
    ],
    component: BlankLayoutComponent,
    path: ''
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)]
})
export class FourOhFourRoutingModule {}
