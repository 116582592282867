<div class="auth-wrapper">
  <div class="auth-header">
    <a class="auth-link" [routerLink]="['/login']">Back to login</a>
  </div>

  <div class="auth-main" *ngIf="(hasResetPassword$ | async | none) && (requiresTokenVerification$ | async | none)">
    <h2 class="auth-title">Forgot your password?</h2>
    <p class="auth-intro">Enter your email address and we'll send reset instructions</p>
    <form class="auth-form" #f="ngForm" (ngSubmit)="f.form.valid && submitEmailReset()" [formGroup]="resetForm">
      <mat-form-field appearance="outline" floatLabel="always" [hideRequiredMarker]="true">
        <mat-label>Email</mat-label>
        <input matInput id="reset-email-password" type="email" placeholder="Email" autocorrect="off"
          autocapitalize="off" spellcheck="false" required formControlName="email" (blur)="validateEmailField()" (keydown)="resetEmailValidation()" tabindex="1">
        <div class="material-icons success auth-field-icon" [inlineSVG]="'/assets/icons/check--thin.svg'"
          *ngIf="isEmailValid"></div>
        <div
          [inlineSVG]="'/assets/icons/attention.svg'"
          *ngIf="isEmailInvalid"
          class="material-icons error"
          matTooltip="Not a valid email address format."
          matTooltipPosition="above"
        ></div>
      </mat-form-field>
      <button color="primary" class="auth-submit-button" type="submit" [disabled]="isBtnDisabled()">
        Send Reset Link
      </button>
      <div class="auth-loading-spinner" *ngIf="loginFormSubmitted$ | async">
        <img src="/assets/loading-spinner.gif" />
      </div>
    </form>
  </div>

  <div class="auth-main" *ngIf="(requiresTokenVerification$ | async)">
    <h2 class="auth-title">Forgot your password</h2>
    <form class="login-form" #f="ngForm" (ngSubmit)="f.form.valid && submitEmailReset()">
      <span class="resend-text otp-detail">Enter the one-time passcode sent to the phone number ending in <span
          class="violet">**{{sessionService.phoneLastTwo}}.</span></span>

      <input class="otp-input token-input" id="otp" name="otp" type="text" placeholder="Enter verification code" autocorrect="off"
        autocapitalize="off" spellcheck="false" required autofocus
        [(ngModel)]="sessionService.otpToken" [ngModelOptions]="{standalone: true}">

      <div class="justify-start">
        <span class="resend-text otp-detail">Haven’t received a code yet? </span>
        <a (click)="submitEmailReset(true)" class="resend-code">Resend Code.</a>
      </div>

      <button mat-button class="auth-submit-button" type="submit" [disabled]="isBtnDisabled()">
        Send Reset Link
      </button>
    </form>
  </div>

  <div class="auth-main" *ngIf="hasResetPassword$ | async">
    <h2 class="auth-title">We've sent you an email to reset your password</h2>
    <p class="auth-intro">
      If an active account exists for <strong>{{sessionService.credentials.email}}</strong>,
      you should receive password reset instructions shortly.
    </p>
    <p class="auth-intro">
      If you need further assistance accessing Flow, please email us at <a href="mailto:support@flowinc.com">support@flowinc.com</a>.
    </p>
    <a color="primary" [routerLink]="['/login']" class="auth-submit-button">
      Back to Login
    </a>
  </div>
</div>
