import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { BlankLayoutModule } from '@app/layout/blank-layout';
import { LabeledHorizontalSeparatorModule } from '@component/labeled-horizontal-separator/labeled-horizontal-separator.module';

import { FourOhFourRoutingModule } from './four-oh-four-routing.module';
import { FourOhFourComponent } from './four-oh-four.component';

@NgModule({
  declarations: [FourOhFourComponent],
  exports: [FourOhFourComponent],
  imports: [
    BlankLayoutModule,
    CommonModule,
    FourOhFourRoutingModule,
    InlineSVGModule,
    LabeledHorizontalSeparatorModule
  ]
})
export class FourOhFourModule {}
