import {
  createRequestsStatusOperator,
  selectRequestStatus,
  StatusState,
  updateRequestStatus,
  withRequestsStatus
} from '@ngneat/elf-requests';
import { createStore, select, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { EntityMember } from '@state/entities';
import { ThinTask } from '@state/task';

import { Investment } from './investment.model';

interface InvestmentProps {
  investment?: Investment;
  loading: boolean;
  members: EntityMember[];
  mobileAsideVisible: boolean;
}

let filterArchivedSignatureRequests = (tasks: ThinTask[]): ThinTask[] => {
  let filteredTasks = tasks.filter((task) => !task.signature_request?.archived);
  return filteredTasks;
};

export const investmentStore$ = createStore(
  { name: 'investment' },
  withProps<InvestmentProps>({
    investment: undefined,
    loading: true,
    members: undefined,
    mobileAsideVisible: false
  }),
  withRequestsStatus<'investment'>()
);

export const trackInvestmentRequestsStatus = createRequestsStatusOperator(investmentStore$);

@Injectable({ providedIn: 'root' })
export class InvestmentRepository {
  investment$ = investmentStore$.pipe(select((state) => state.investment));
  loading$ = investmentStore$.pipe(
    selectRequestStatus('investment'),
    map((status) => status.value !== 'success')
  );
  members$ = investmentStore$.pipe(select((state) => state.members));
  mobileAsideVisible$ = investmentStore$.pipe(select((state) => state.mobileAsideVisible));
  tasks$ = investmentStore$.pipe(
    select((state) => state.investment?.workflow?.tasks),
    map(filterArchivedSignatureRequests)
  );

  getState(): InvestmentProps & { requestsStatus: Record<'investment', StatusState> } {
    return investmentStore$.getValue();
  }

  reset(): void {
    investmentStore$.reset();
  }

  resetRequestStatus(): void {
    investmentStore$.update(updateRequestStatus('investment', 'idle'));
  }

  updateStore(newState: Partial<InvestmentProps>): void {
    investmentStore$.update(
      (state) => ({
        ...state,
        ...newState
      }),
      updateRequestStatus('investment', 'success')
    );
  }
}
