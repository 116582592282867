import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { EntityTeamMember } from '@state/entities';

export interface PortfolioReport {
  contact_name: string;
  contact_email?: string;
  investments: PortfolioInvestment[];
  organization_logo?: string;
  organization_name: string;
  total_commitment: number;
  total_contributed: number;
  total_distributions: string;
  total_nav: string;
  total_net_moic: string;
  total_unfunded: number;
  total_value: string;
}
export interface PortfolioInvestment {
  as_of_date: string;
  commitment: number;
  contributed_percent: number;
  contributed: number;
  distributions: string;
  legal_name: string;
  lp_interest: string;
  nav: string;
  net_moic: string;
  position: string;
  total_value: string;
  unfunded: number;
  vehicle_name: string;
  vintage: string;
}

export interface OrganizationState {
  portfolioReport?: PortfolioReport;
  users?: EntityTeamMember[];
}

export let initialOrganizationState = {
  portfolioReport: undefined,
  users: undefined
};

export function createInitialState(): OrganizationState {
  return initialOrganizationState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'organization', resettable: true })
export class OrganizationStore extends Store<OrganizationState> {
  constructor() {
    super(createInitialState());
  }
}
