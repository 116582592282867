import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ApplicationState {
  notFound: boolean;
  requests: string[];
}

export let intialApplicationState = {
  notFound: false,
  requests: []
};

export function createInitialState(): ApplicationState {
  return intialApplicationState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application', resettable: true })
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super(createInitialState());
  }
}
