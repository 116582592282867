import { catchError, filter, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subscription } from 'rxjs';

import { environment } from '@env/environment';
import { SessionQuery } from '@state/session/session.query';
import { standardError } from '@helpers';

import { Organization, User, UserState, UserStore } from './user.store';

interface UserResponse {
  current_time: Date;
  organizations: Organization[];
  recent_app_updates: unknown;
  user: User;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private sessionQuery: SessionQuery,
    private http: HttpClient,
    private userStore: UserStore,
    private snackBar: MatSnackBar
  ) {}

  getInfo(): void {
    this.http
      .get<UserResponse>(`${environment.apiUrl}/user`)
      .pipe(
        filter((response: UserResponse) => !!response),
        tap({
          next: (response: UserResponse) => {
            let user: User;
            let userState: UserState;
            this.userStore.update((state: UserState): UserState => {
              user = { ...state.user, ...response.user };
              userState = { hasFetchedUser: true, user };
              return { ...userState };
            });
          }
        }),
        catchError((error: HttpErrorResponse) => {
          standardError(error);
          return of(null);
        })
      )
      .subscribe();
  }

  getInvestmentAccounts$(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/user/investment_accounts`);
  }

  requestPasswordEmail(email: string): Subscription {
    let url = `${environment.apiUrl}/request_password_creation`;
    return this.http.post(url, { email }).subscribe();
  }

  setMfaStatus(enable_mfa: boolean): void {
    this.userStore.update((state) => {
      let user = Object.assign({}, state.user, { enable_mfa });
      return { user };
    });
  }

  setMobilePhone(mobile_phone: string): void {
    this.userStore.update((state) => {
      let user = Object.assign({}, state.user, { mobile_phone });
      return { user };
    });
  }

  setMobileVerified(mobile_verified: boolean): void {
    this.userStore.update((state) => {
      let user = Object.assign({}, state.user, { mobile_verified });
      return { user };
    });
  }

  setUser(user: User): void {
    this.userStore.update({ user });
  }

  setUserEmail(email: string): void {
    this.userStore.update((state) => {
      let user = Object.assign({}, state.user, { email });
      return { user };
    });
  }

  setUserFromAuth(): void {
    this.userStore.update({ user: this.sessionQuery.user() });
    this.getInfo();
  }

  unsetUser(): void {
    this.userStore.update({ hasFetchedUser: false, user: undefined });
  }

  updateEmail$(email: any, password: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/update_email`, { email, password });
  }

  updateMfa$(email: any, enable_mfa: any, mobile_phone: any, password: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/enable_mfa`, {
      email,
      enable_mfa,
      mobile_phone,
      password
    });
  }

  updateUser$(user: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/user`, user);
  }

  showSuccessMessage(): void {
    this.snackBar.open('Update was successful');
  }

  showErrorMessage(errorMessage: string): void {
    this.snackBar.open(errorMessage, '', { panelClass: 'snack-error' });
  }
}
