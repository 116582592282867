import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, finalize, ignoreElements } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import zxcvbn, { ZXCVBNScore } from 'zxcvbn';

import { ApplicationService } from '@state/application';
import { AuthLayoutService } from '@state/auth-layout';
import { CreateByTokenResponse, Credentials, SessionService } from '@state/session';
import { environment } from '@env/environment';
import { MessageBoxType } from '@component/message-box';
import { PasswordValidator } from '@helpers/password.validator';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-reset-password',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  disabled = false;
  forgetPasswordLink = `${environment.docsUrl}/forgot-password`;
  loading = true;
  messageBoxType = MessageBoxType;
  peek$: Observable<Credentials>;
  peekError$: Observable<HttpStatusCode>;
  resetPasswordForm: UntypedFormGroup;

  constructor(
    private applicationService: ApplicationService,
    private authLayoutService: AuthLayoutService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private snackBar: MatSnackBar,
    private titleService: Title
  ) {}

  ngOnInit() {
    let token: string = this.route.snapshot.params.token;
    this.titleService.setTitle('Reset Password | Flow');
    this.resetPasswordForm = this.formBuilder.group(
      {
        confirmPassword: ['', [Validators.required]],
        password: ['', [Validators.required, PasswordValidator.strength]]
      },
      { validators: PasswordValidator.confirm }
    );
    this.peek$ = this.sessionService.peek$(token).pipe(finalize(() => (this.loading = false)));
    this.peekError$ = this.peek$.pipe(
      ignoreElements(),
      catchError((error: HttpErrorResponse) => of(error.status)),
      filter((status: HttpStatusCode) => status === HttpStatusCode.NotFound)
    );
  }

  get passwordScore(): ZXCVBNScore {
    let password: string = this.resetPasswordForm.get('password').value;
    return zxcvbn(password).score;
  }

  onSubmit(): void {
    if (this.disabled) return;
    this.disabled = true;

    this.applicationService.pushLoading('reset-password');
    let reset_password_token = this.route.snapshot.params.token;

    let params = {
      password: this.resetPasswordForm.get('password').value,
      reset_password_token
    };

    this.sessionService
      .signup_by_token$(params)
      .pipe(
        catchError((error) => {
          console.error(error);
          this.disabled = false;
          this.applicationService.popLoading('reset-password');

          this.snackBar.open(
            'An error has occurred. Your reset password link may have expired or already been used. ' +
              'If you cannot log in, please reset your password, or contact customer support for further ' +
              'assistance.',
            '',
            { panelClass: 'snack-error' }
          );
          return of({ error: true });
        }),
        filter((data) => !data.error)
      )
      .subscribe({
        next: (data: CreateByTokenResponse) => {
          if (data.auth_token) {
            this.sessionService.setSession(data.auth_token);
            void this.router.navigate(['/dashboard']);
          } else {
            this.authLayoutService.setMessage('Use your new credentials to log in.');
            void this.router.navigate(['/login']);
          }
        }
      });
  }
}
