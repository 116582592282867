import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { EntityMember, Member } from '@state/memberships';
import { extractLeadingNumber } from '@helpers';
import { environment } from '@env/environment';

import {
  AddMemberOptions,
  Entity,
  EntityIds,
  EntityMemberRoleType,
  EntityParams,
  EntityTeam,
  GetEntityMembersParams,
  IDLike,
  RemindMemberParams,
  RemoveEntityMemberParams,
  UpdateEntityMemberParams
} from './entity.model';
import { EntitiesStore } from './entities.store';

@Injectable({ providedIn: 'root' })
export class EntitiesService {
  constructor(private http: HttpClient, private entitiesStore: EntitiesStore) {}

  addMember$({
    contact_id,
    entityId,
    member,
    message,
    organization_id,
    providerId,
    send_email,
    subject,
    vehicle_id
  }: AddMemberOptions): Observable<null> {
    if (send_email === undefined) send_email = true;
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entityId}/members`;
    let options: object = {};

    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.post<null>(
      entitiesEndpoint,
      { contact_id, members: [member], message, organization_id, send_email, subject, vehicle_id },
      options
    );
  }

  addMembers$(entityId: ID, emails: string): Observable<null> {
    let bulkMembersURL = `${environment.apiUrl}/v1/entities/${entityId}/members/create_in_bulk`;
    return this.http.post<null>(bulkMembersURL, { emails });
  }

  archive$(entityID: ID): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entityID}/archive`;
    return this.http.post<null>(url, {});
  }

  createEntity$(params: Partial<EntityParams>): Observable<Entity> {
    let entityParams = { bank_account: {}, roles: [], vehicle: {}, ...params };
    return this.http.post<Entity>(`${environment.apiUrl}/v1/entities`, entityParams);
  }

  createAndStoreEntity$(params: EntityParams): Observable<Entity> {
    return this.createEntity$(params).pipe(
      take(1),
      tap({
        next: (entity: Entity) => {
          if (entity) this.entitiesStore.add(entity);
        }
      })
    );
  }

  // TODO: Define these params
  get$(entity?: IDLike): Observable<Entity[]> {
    let path = entity ? `/v1/entities/${entity}/entities` : '/v1/entities';
    return this.http.get<Entity[]>(`${environment.apiUrl}${path}`);
  }

  getChildren$(entity: IDLike, provider_id?: ID): Observable<Entity[]> {
    let url = `${environment.apiUrl}/v1/entities/${entity}/children`;
    let params = new HttpParams();
    if (provider_id) params = params.append('provider_id', provider_id);
    return this.http.get<Entity[]>(url, { params }).pipe(
      take(1),
      map((entities: Entity[]) =>
        entities.sort((a, b) => {
          let numberA = extractLeadingNumber(a.name);
          let numberB = extractLeadingNumber(b.name);
          if (numberA && numberB) return numberA - numberB;
          return a.name.localeCompare(b.name);
        })
      )
    );
  }

  getEntityMembers$({
    contact_id,
    entity_id,
    vehicle_id
  }: GetEntityMembersParams): Observable<EntityMember[]> {
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/members`;
    let params = {};

    if (contact_id) params['contact_id'] = contact_id;
    if (vehicle_id) params['vehicle_id'] = vehicle_id;

    return this.http.get<EntityMember[]>(entitiesEndpoint, { params });
  }

  getMembers$({
    entity_id,
    include_invisible,
    contact_id,
    vehicle_id
  }: GetEntityMembersParams): Observable<Member[]> {
    let params = { include_invisible, users: 'true' };

    if (contact_id) params['contact_id'] = contact_id;
    if (vehicle_id) params['vehicle_id'] = vehicle_id;

    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/members`;
    return this.http.get<Member[]>(entitiesEndpoint, { params });
  }

  getPortfolioCompanies$(entity: IDLike): Observable<Entity[]> {
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity}/related_entities`;
    let params = { entity_role: 'portfolio_company' };
    return this.http.get<Entity[]>(entitiesEndpoint, { params });
  }

  getRelatedEntities$(entity: IDLike, role?: string): Observable<Entity[]> {
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity}/related_entities`;
    let params = {};
    if (role) params = { entity_role: role };
    return this.http.get<Entity[]>(entitiesEndpoint, { params });
  }

  getTeams$(entity_id: IDLike): Observable<EntityTeam[]> {
    let teamsUrl = `${environment.apiUrl}/v1/entities/${entity_id}/teams`;
    return this.http.get<EntityTeam[]>(teamsUrl);
  }

  remindMember$({
    contact_id,
    entity_id,
    member_id,
    provider_id,
    vehicle_id
  }: RemindMemberParams): Observable<null> {
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/members/${member_id}/send_reminder`;
    let options: object = {};
    if (provider_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_id as string });
      options = { headers };
    }

    return this.http.post<null>(entitiesEndpoint, { contact_id, vehicle_id }, options);
  }

  removeMember$({
    contact_id,
    entity_id,
    member_id,
    provider_id,
    vehicle_id
  }: RemoveEntityMemberParams): Observable<null> {
    let entitiesEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/members/${member_id}`;
    let options: object = {};
    if (provider_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_id as string });
      options = { headers };
    }

    options = { ...options, body: { contact_id, vehicle_id } };
    return this.http.delete<null>(entitiesEndpoint, options);
  }

  reset(): void {
    this.entitiesStore.reset();
  }

  setEntityRoleType$(
    { entity_id, entity_member_id, investment_id }: EntityIds,
    role?: EntityMemberRoleType
  ): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/entity_member_roles`;
    let roleParam = role || EntityMemberRoleType.PrimaryContact;
    return this.http.post<null>(url, {
      entity_member_id,
      investment_id,
      role: roleParam
    });
  }

  updateTeamMember$({
    member,
    entity_id,
    entity_member_roles,
    member_id,
    contact_id,
    vehicle_id
  }: UpdateEntityMemberParams): Observable<null> {
    let entityMembersUpdateEndpoint = `${environment.apiUrl}/v1/entities/${entity_id}/members/${member_id}`;

    return this.http.patch<null>(entityMembersUpdateEndpoint, {
      ...member,
      contact_id,
      entity_member_roles,
      vehicle_id
    });
  }
}
