import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Entity } from '@state/entities';
import { environment } from '@env/environment';

import { MembershipEntityRepository } from './membership-entity.repository';

@Injectable({ providedIn: 'root' })
export class MembershipEntityService {
  constructor(private http: HttpClient, private membershipEntityRepository: MembershipEntityRepository) {}

  get$(id: ID, providerId?: ID): Observable<Entity> {
    let url = `${environment.apiUrl}/v1/entities/${id}`;
    let options = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }
    return this.http.get<Entity>(url, options);
  }

  getAndStoreMembershipEntity$(id: ID, providerId?: ID): Observable<Entity> {
    return this.get$(id, providerId).pipe(
      tap({
        next: (membershipEntity) => {
          this.membershipEntityRepository.setMembershipEntity(membershipEntity);
        }
      })
    );
  }
}
