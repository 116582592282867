import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { debounceTime, filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/shared/services/vendor';
import { ID } from '@datorama/akita';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Md5 } from 'ts-md5';
import { Observable } from 'rxjs';

import { ApplicationQuery } from '@state/application/application.query';
import { ApplicationService } from '@state/application/application.service';
import { environment } from '@env/environment';
import { HotjarService } from '@state/hotjar';
import { LaunchDarklyService } from '@state/launch-darkly';
import { PusherService } from '@state/pusher';
import { RollbarService } from '@state/rollbar';
import { SessionQuery } from '@state/session';
import { SignatureRequestQuery } from '@state/signature-request';
import { TaskQuery } from '@state/task';
import { UserQuery, UserService } from '@state/user';
import { version } from '@env/version';
import { ZendeskService } from '@state/zendesk';

declare let window: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-documents-app',
  styleUrls: ['./documents-app.component.scss'],
  templateUrl: './documents-app.component.html'
})
export class DocumentsAppComponent implements OnInit {
  notFound$ = this.applicationQuery.select('notFound');
  requests$ = this.applicationQuery.select('requests').pipe(debounceTime(0));

  rollbar = window.globalRollbar = inject(RollbarService);
  router = window.globalRouter = inject(Router);
  snackBar = window.globalSnackBar = inject(MatSnackBar);

  // Must be defined even if unused to work:
  // * googleAnalyticsService
  // * launchDarklyService
  // * ZendeskService
  constructor(
    private applicationQuery: ApplicationQuery,
    private applicationService: ApplicationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private hotjarService: HotjarService,
    private launchDarklyService: LaunchDarklyService,
    private pusherService: PusherService,
    private route: ActivatedRoute,
    private sessionQuery: SessionQuery,
    private signatureRequestQuery: SignatureRequestQuery,
    private taskQuery: TaskQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private zendeskService: ZendeskService
  ) {}

  ngOnInit() {
    if (this.sessionQuery.loggedIn()) this.userService.setUserFromAuth();

    let appCue: string = environment.APP_CUE;
    if (appCue && appCue !== 'false') {
      this.loadAppCues(appCue);
      this.appCuesReady$().subscribe({ next: this.watchUserChanges });
    }

    this.googleAnalyticsService.loadGtagScript();
    this.watchRouteChanges();
    this.watchVersionChanges();

    if (window.navigator.webdriver) this.setHeadlessStyles();
    window.fl = { version };
  }

  showUpgradeModal = (): void => {
    this.snackBar
      .open('A newer version of Flow is available!', 'Refresh', { duration: 259200 })
      .onAction()
      .subscribe({ next: () => location.reload() });
  };

  watchUserChanges = (): void => {
    let userId: ID;
    this.userQuery
      .select('user')
      .pipe(filter((user) => !!user && user.id !== userId))
      .subscribe({
        next: (user) => {
          userId = user.id;
          window.Appcues.identify(userId, {
            isAdmin: user.admin,
            legalTeam: user.is_legal_team
          });
        }
      });
  };

  appCuesReady$(): Observable<null> {
    return new Observable((subscriber) => {
      let tries = 0;

      let checkForAppcues = () => {
        let appCues = window.Appcues;
        if (appCues) {
          subscriber.next();
          subscriber.complete();
        } else {
          if (tries >= 20) {
            subscriber.error('could not load appcues');
            subscriber.complete();
          } else {
            tries++;
            setTimeout(checkForAppcues, 250);
          }
        }
      };
      checkForAppcues();
    });
  }

  loadAppCues(file: string): void {
    let appcuesScript = document.createElement('script');
    appcuesScript.src = `https://apc.flowinc.app/${file}.js`;
    document.head.appendChild(appcuesScript);
  }

  identifyHotJarUser(): void {
    let user_id = this.userQuery.getValue().user?.user_id;
    let investment_id: ID;
    let vehicle_id: ID;
    let fundId = this.route.snapshot.firstChild?.params?.fund_id;

    let { task } = this.taskQuery.getValue();
    let { signatureRequest } = this.signatureRequestQuery.getValue();

    if (fundId) vehicle_id = fundId;

    if (task) {
      investment_id = task.investment_id;
      vehicle_id = task.vehicle?.id;
    }

    if (signatureRequest) {
      investment_id = signatureRequest.investment_id;
      vehicle_id = signatureRequest.vehicle_id;
    }

    this.hotjarService.identify(user_id, {
      admin: this.userQuery.isAdmin(),
      doc_admin: this.userQuery.isDocAdmin(),
      environment: environment.runtimeEnv,
      investment_id,
      user_id,
      vehicle_id
    });
  }

  setHeadlessStyles(): void {
    let headlessStyles = document.createElement('link');
    headlessStyles.rel = 'stylesheet';
    headlessStyles.href = '/assets/headless.css';
    document.head.appendChild(headlessStyles);
  }

  watchRouteChanges(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: () => {
        this.applicationService.reset();
        if (environment.APP_CUE && environment.APP_CUE !== 'false') window.Appcues?.page();
        this.identifyHotJarUser();
      }
    });
  }

  watchVersionChanges(): void {
    let { showUpgradeModal } = this;
    let channelId = Md5.hashStr(`application/doc/${environment.runtimeEnv}`);
    let channel = this.pusherService.pusher.subscribe(channelId);

    channel.bind('update', showUpgradeModal);
  }
}
