import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import Rollbar, { LogArgument } from 'rollbar';

import { environment } from '@env/environment';
import { version } from '@env/version';

// ------------------------------------------------------------------------------
// Rollbar Configuration
// ------------------------------------------------------------------------------
const rollbarConfig = {
  accessToken: environment.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        code_version: version.hash,
        guess_uncaught_frames: true,
        source_map_enabled: true
      }
    },
    environment: environment.runtimeEnv,
    server: {
      root: 'webpack:///./'
    }
  },
  verbose: environment.rollbarErrorVerbose
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    let rollbarError: LogArgument = err.originalError || err;
    let error: Error = err;

    this.handleChunkError(error);

    this.rollbar.error(rollbarError);
  }

  handleChunkError(error?: Error): void {
    // Note: Used to handle chunk failed error:
    // https://www.pivotaltracker.com/story/show/184258714
    let chunkFailedMessage = /Loading chunk \d+ failed/;
    if (chunkFailedMessage.test(error?.message)) return;
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}
