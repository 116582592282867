/*
  Vehicle Service
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ID } from '@datorama/akita';

import { environment } from '@env/environment';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService extends BaseService {
  request: any;
  currentRequestForm: any;

  constructor(private http: HttpClient) {
    super();
  }

  getEntities(organizationId: ID, type?: string, callbacks?: any): Subscription {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    let url = `${environment.apiUrl}/organizations/${organizationId}/entities`;

    if (type) {
      url += '?type=' + type;
    }

    return this.http.get(url).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data) => {
        this.applyCallback('success', callbacks, data);
      }
    });
  }
}
