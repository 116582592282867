import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApplicationService } from '@state/application';
import { EntityMember } from '@state/entities';
import { environment } from '@env/environment';

import { Investment } from './investment.model';
import { InvestmentRepository, trackInvestmentRequestsStatus } from './investment.repository';

@Injectable({ providedIn: 'root' })
export class InvestmentService {
  constructor(
    private applicationService: ApplicationService,
    private http: HttpClient,
    private investmentRepo: InvestmentRepository
  ) {}

  get(id: ID): Subscription {
    return this.getAndStoreInvestment$(id).subscribe({
      error: (error) => {
        if (error.status === HttpStatusCode.NotFound) this.applicationService.setNotFound();
        this.investmentRepo.resetRequestStatus();
      },
      next: () => this.investmentRepo.resetRequestStatus()
    });
  }

  get$(id: ID): Observable<Investment> {
    let url = `${environment.apiUrl}/v1/investments/${id}`;
    return this.http.get<Investment>(url);
  }

  getAndStoreInvestment$(id: ID, providerId?: ID): Observable<Investment> {
    let url = `${environment.apiUrl}/v1/investments/${id}`;

    let options: object = {};
    if (providerId) {
      let headers = new HttpHeaders({ 'Provider-Id': providerId as string });
      options = { headers };
    }

    return this.http.get<Investment>(url, options).pipe(
      trackInvestmentRequestsStatus('investment'),
      tap({
        next: (investment) => {
          this.investmentRepo.updateStore({ investment });
        }
      })
    );
  }

  getAndStoreMembers$(id: ID): Observable<EntityMember[]> {
    return this.getMembers$(id).pipe(
      trackInvestmentRequestsStatus('investment'),
      tap({
        next: (members) => {
          this.investmentRepo.updateStore({ members });
        }
      })
    );
  }

  getMembers$(id: ID): Observable<EntityMember[]> {
    let url = `${environment.apiUrl}/v1/investments/${id}/members`;
    return this.http.get<EntityMember[]>(url);
  }

  setInvestmentAccount$({
    investment_id,
    investment_account_id
  }: {
    investment_id: ID;
    investment_account_id: ID;
  }): Observable<null> {
    let url = `${environment.apiUrl}/v1/investments/${investment_id}`;
    return this.http.put<null>(url, { investment_account_id });
  }

  toggleMobileAside(mobileAsideVisible: boolean): void {
    this.investmentRepo.updateStore({ mobileAsideVisible });
  }
}
