import { Injectable } from '@angular/core';

import { waitForElement } from '@helpers/html';

import { ZendeskConfigAbstract } from './zendesk.model';

// This class is largely just copy pasted from
// https://github.com/AlisonVilela/ngx-zendesk-webwidget/blob/master/src/lib/ngx-zendesk-webwidget.service.ts
// This package is now unmaintained, so we're just copying the code here

@Injectable({ providedIn: 'root' })
export class ZendeskService {
  private readonly window: any;
  private initialized = false;
  private _zE: any;

  constructor(private zendeskConfig: ZendeskConfigAbstract) {
    if (!this.zendeskConfig.accountUrl) return;

    this.window = window as any;

    if (!this.zendeskConfig.lazyLoad) void this.initZendesk();
  }

  initZendesk(): Promise<boolean> {
    const window = this.window;
    const config = this.zendeskConfig;

    /* eslint-disable */
    window.zEmbed || function(e, t) {
      let n, o, d, i, s, a = []
      let r = document.createElement("iframe")
      window.zEmbed = function() {
        a.push(arguments)
      }
      window.zE = window.zE || window.zEmbed
      r.src = "javascript:false"
      r.title = ""
      r.style.cssText = "display: none"
      d = document.getElementsByTagName(config.injectionTag || "head")
      d = d[d.length - 1]
      d.parentNode.insertBefore(r, d)
      i = r.contentWindow
      s = i.document
      try {
        o = s
      } catch (e) {
        n = document.domain
        r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);'
        o = s
      }
      o.open()._l = function() {
        let e = this.createElement("script")
        n && (this.domain = n)
        e.id = "js-iframe-async"
        e.src = "https://static.zdassets.com/ekr/snippet.js"
        this.t += new Date
        this.zendeskHost = config.accountUrl
        this.zEQueue = a
        this.body.appendChild(e)
      }
      o.write('<body onload="document._l();">')
      o.close()
    }();
    /* eslint-enable */

    return this.finishLoading();
  }

  private finishLoading(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const timeout = setTimeout(() => {
        this.initialized = false;
        reject(Error('timeout'));
      }, this.zendeskConfig.timeOut || 30000); // 30 seconds

      this.window.zE(() => {
        this.zendeskConfig.callback(this.window.zE);
        this.initialized = true;
        this._zE = this.window.zE;
        clearTimeout(timeout);
        resolve(true);
      });
    });
  }

  toggleZendeskWidgetDisplay(): void {
    if (!this.zendeskConfig.accountUrl) return;

    let zendeskWidgetSelector = '[title="Button to launch messaging window"]';

    // We update HxW instead of display type because the zendesk script overwrites the display many times
    waitForElement(zendeskWidgetSelector, (element) => {
      if (element.style.height !== '0px') {
        element.style.height = '0px';
        element.style.width = '0px';
      } else {
        element.style.height = '64px';
        element.style.width = '64px';
      }
    });
  }

  get isInitialized(): boolean {
    return this.initialized;
  }

  get zE(): any {
    // This is necessary because the zE object is controlled by
    // zendesk and we have no insight or control over its current iteration
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._zE;
  }
}
