import { ID } from '@state/base';
import { OverviewCard } from '@component/overview-cards';

export interface AvailableReport {
  description: string;
  query_params: Record<string, string>;
  title: string;
  type: ReportType;
  url: string;
}

export interface ReportsResponse {
  available_reports?: AvailableReport[];
  summary_stats?: OverviewCard[];
}

export interface ReportState extends ReportsResponse {
  reportUrl?: string;
}

export enum ReportType {
  CapitalCalls = 'capital_calls',
  InvestorContacts = 'investor_contacts',
  Investors = 'investors'
}

export enum ReportStatus {
  Complete = 'complete',
  Failed = 'failed'
}

export interface ReportResponse {
  organization_report_id: ID;
}

export interface ReportPusherMessage {
  status: ReportStatus;
  html_signed_url: string;
}
