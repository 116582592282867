import { BaseModel, ID } from '@state/base';
import { EntityMemberRoleType } from '@state/entities';

export interface AddEntityRelationshipParams {
  actor_id: ID;
  actor_role: string;
  subject_role: string;
}

export interface MessageTemplate {
  id?: ID;
  body?: string;
  subject?: string;
  tag_names?: string[];
  tags?: any[];
  title?: string;
}

// Feature Configuration Models
export interface FeatureConfigurationParams {
  entity_id: ID;
  provider_id?: ID;
  type?: string;
  enabled?: boolean;
}

export interface FeatureRoleConfigurationParams {
  role_action: FeatureRoleConfigurationActionType; // "action" is a reserve word in Rails controller params
  enabled: boolean;
  entity_id: ID;
  role: EntityMemberRoleType;
  type: FeatureConfigurationType;
}

export interface FeatureConfiguration extends BaseModel, FeatureConfigurationListing {}

export interface FeatureConfigurationUserPermissions {
  feature_configuration: FeatureConfigurationState;
  user_permissions: string[];
}

export interface FeatureConfigurationState {
  type: string;
  enabled: boolean;
}
export interface FeatureConfigurationListing {
  enabled: boolean;
  role_configurations: FeatureRoleConfiguration[];
  type: FeatureConfigurationType;
}

export interface FeatureConfigurationOption {
  actions: FeatureRoleConfigurationActionType[];
  roles: EntityMemberRoleType[];
  type: FeatureConfigurationType;
}

export type MappedConfigurationOptions = Map<
  FeatureConfigurationType,
  Map<EntityMemberRoleType, Map<FeatureRoleConfigurationActionType, boolean>>
>;

export interface FeatureRoleConfiguration {
  role: EntityMemberRoleType;
  actions: string[];
}

export enum FeatureConfigurationType {
  QuestionnaireReview = 'questionnaire_review'
}

export const featureConfigurationTypeLabels = {
  questionnaire_review: 'Questionnaire Review'
};

// TODO: rename to be specifically scoped to questionnaire review actions
export enum FeatureRoleConfigurationActionType {
  Approve = 'approve_comments',
  NoComments = 'no_comments',
  Send = 'send_comments',
  Submit = 'submit_comments'
}

export const featureRoleConfigurationActionLabels = {
  approve_comments: 'Approve Comments',
  no_comments: 'No comments',
  send_comments: 'Send Comments',
  submit_comments: 'Submit Comments'
};
