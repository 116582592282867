import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { EntityMember } from '@state/entities';

import {
  Investor,
  InvestorDocument,
  InvestorInvestmentAccount,
  InvestorInvestmentAccountListItem,
  InvestorTask
} from './investor.model';
import { InvestorRepository } from './investor.repository';

export interface InvestmentValues {
  carry_percent?: string;
  contributed_amount?: string;
  contributed_at?: string;
  customer_code?: string;
  management_fee_percent?: string;
  notes?: string;
  ownership_units?: string;
  total_due?: string;
  total_received_amount?: string;
}

export interface InvestmentRequestOptions {
  investment_id: string | ID;
  provider_id?: string | ID;
  vehicle_id: string | ID;
}

export interface InvestmentInvitationRequestOptions {
  investment_id: string | ID;
  provider_id?: string | ID;
  vehicle_id: string | ID;
  entity_member_id?: string | ID;
}
export interface InvestmentMemberInvitation {
  invitation_url: string;
}

export interface InvestmentTaskOptions {
  investment_id: string | ID;
  task_id: string | ID;
  vehicle_id: string | ID;
}

export interface InvestmentMemberParams {
  entity_id: string | ID;
  member_id: string | ID;
}

@Injectable({ providedIn: 'root' })
export class InvestorService {
  constructor(private http: HttpClient, private investorRepository: InvestorRepository) {}

  fetchAndStoreInvestorDocuments$({
    investment_id,
    vehicle_id
  }: InvestmentRequestOptions): Observable<InvestorDocument[]> {
    let params = { filter: 'not_shared' };
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/documents`;

    return this.http.get(url, { params }).pipe(
      tap({
        next: (investorDocuments: InvestorDocument[]) => {
          this.investorRepository.setInvestorDocuments(investorDocuments);
        }
      })
    );
  }

  fetchAndStoreInvestmentAccountDocuments$(investment_id: ID | string): Observable<InvestorDocument[]> {
    let url = `${environment.apiUrl}/v1/investments/${investment_id}/documents/investment_account`;

    return this.http.get(url).pipe(
      tap({
        next: (investmentAccountDocuments: InvestorDocument[]) => {
          this.investorRepository.setInvestmentAccountDocuments(investmentAccountDocuments);
        }
      })
    );
  }

  fetchAndStoreStagedDocuments$(investment_id: ID): Observable<InvestorDocument[]> {
    let url = `${environment.apiUrl}/v1/investments/${investment_id}/documents/staged`;
    return this.http.get(url).pipe(
      tap({
        next: (stagedDocuments: InvestorDocument[]) => {
          this.investorRepository.setStagedDocuments(stagedDocuments);
        }
      })
    );
  }

  fetchInvestor({ investment_id, vehicle_id }: InvestmentRequestOptions): void {
    this.http.get(`${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}`).subscribe({
      error: (error) => console.error(error),
      next: (investor: Investor) => {
        this.investorRepository.setInvestor(investor);
      }
    });
  }

  fetchInvestorDocuments({ investment_id, vehicle_id }: InvestmentRequestOptions): void {
    let params = { filter: 'not_shared' };
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/documents`;

    this.http.get(url, { params }).subscribe({
      error: (error) => console.error(error),
      next: (investorDocuments: InvestorDocument[]) => {
        this.investorRepository.setInvestorDocuments(investorDocuments);
      }
    });
  }

  fetchInvestmentAccountDocuments(investment_id: ID | string): void {
    let url = `${environment.apiUrl}/v1/investments/${investment_id}/documents/investment_account`;

    this.http.get(url).subscribe({
      error: (error) => console.error(error),
      next: (investmentAccountDocuments: InvestorDocument[]) => {
        this.investorRepository.setInvestmentAccountDocuments(investmentAccountDocuments);
      }
    });
  }

  fetchInvestorMembers({ investment_id, vehicle_id }: InvestmentRequestOptions): void {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/members`;
    this.http.get(url).subscribe({
      error: (error) => console.error(error),
      next: (investorMembers: EntityMember[]) => {
        this.investorRepository.setInvestorMembers(investorMembers);
      }
    });
  }

  fetchInvestorMemberInvitation$({
    entity_member_id, investment_id, vehicle_id
  }: InvestmentInvitationRequestOptions): Observable<InvestmentMemberInvitation> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/members/${entity_member_id}/invitation`;
    return this.http.get<InvestmentMemberInvitation>(url);
  }

  fetchInvestorTasks({ investment_id, vehicle_id }: InvestmentRequestOptions): void {
    this.fetchAndStoreInvestorTasks$({ investment_id, vehicle_id }).subscribe({
      error: (error) => console.error(error)
    });
  }

  completeInvestorTask$({ investment_id, task_id, vehicle_id }: InvestmentTaskOptions): Observable<null> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investments/${investment_id}/tasks/${task_id}/complete`;
    return this.http.post<null>(url, {});
  }

  removeInvestorTask$({ investment_id, task_id, vehicle_id }: InvestmentTaskOptions): Observable<null> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investments/${investment_id}/tasks/${task_id}`;
    return this.http.delete<null>(url, {});
  }

  fetchAndStoreInvestorTasks$({
    investment_id,
    vehicle_id
  }: InvestmentRequestOptions): Observable<InvestorTask[]> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investments/${investment_id}/tasks`;
    return this.http.get<InvestorTask[]>(url).pipe(
      filter((investorTasks) => !!investorTasks),
      tap({
        next: (investorTasks: InvestorTask[]) => {
          this.investorRepository.setInvestorTasks(investorTasks);
        }
      })
    );
  }

  getAndStoreInvestor$({
    investment_id,
    provider_id,
    vehicle_id
  }: InvestmentRequestOptions): Observable<Investor> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}`;
    let headers: HttpHeaders;

    if (provider_id) headers = new HttpHeaders({ 'Provider-Id': (provider_id as string) });

    return this.http.get<Investor>(url, { headers }).pipe(
      tap({
        next: (investor) => {
          this.investorRepository.setInvestor(investor);
        }
      })
    );
  }

  getInvestorDetails$({
    investment_id,
    vehicle_id
  }: InvestmentRequestOptions): Observable<InvestorInvestmentAccount> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/investment_account`;
    return this.http.get<InvestorInvestmentAccount>(url);
  }

  getInvestorAuditTrail$({ investment_id, vehicle_id }: InvestmentRequestOptions): Observable<Blob> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/signature_audit_trail`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getInvestorInvestmentAccounts$({
    investment_id,
    vehicle_id
  }: InvestmentRequestOptions): Observable<InvestorInvestmentAccountListItem[]> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/investment_accounts`;
    return this.http.get<InvestorInvestmentAccountListItem[]>(url);
  }

  getInvestorMembers$({ investment_id, vehicle_id }: InvestmentRequestOptions): Observable<EntityMember[]> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}/members`;
    return this.http.get<EntityMember[]>(url);
  }

  remindMember$({ entity_id, member_id }: InvestmentMemberParams): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/members/${member_id}/send_reminder`;
    return this.http.post<null>(url, {});
  }

  removeMember$({ entity_id, member_id }: InvestmentMemberParams): Observable<null> {
    let url = `${environment.apiUrl}/v1/entities/${entity_id}/members/${member_id}`;
    return this.http.delete<null>(url, {});
  }

  updateTags$(
    document: InvestorDocument,
    tags: string[]
  ): Observable<{ investorDocuments: InvestorDocument }> {
    let url = `${environment.apiUrl}/v1/documents/${document.id}/update_tags`;
    return this.http.post<{ investorDocuments: InvestorDocument }>(url, { tags }).pipe(
      tap({
        next: () => {
          this.investorRepository.updateInvestorDocumentTags(document, tags);
        }
      })
    );
  }

  unsetInvestorDocuments(): void {
    this.investorRepository.setInvestorDocuments(undefined);
  }

  unsetInvestorTasks(): void {
    this.investorRepository.setInvestorTasks(undefined);
  }

  updateInvestmentState$(
    investment: Investor,
    type: string,
    author_id?: string,
    sub_type?: string
  ): Observable<null> {
    let url = `${environment.apiUrl}/v1/investment_states/${investment.state.id}`;
    let params = {
      author_id,
      investment_id: investment.id,
      sub_type,
      type
    };
    return this.http.put<null>(url, params);
  }

  updateInvestmentValues$({
    investment_id,
    params,
    vehicle_id
  }: {
    investment_id: string | ID;
    params: InvestmentValues;
    vehicle_id: string | ID;
  }): Observable<Investor> {
    let url = `${environment.apiUrl}/v1/vehicles/${vehicle_id}/investors/${investment_id}`;
    return this.http.put<Investor>(url, params);
  }
}
