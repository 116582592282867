import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { FundDocumentService, VisibilityDocument } from '@state/fund-document';

@Injectable({
  providedIn: 'root'
})
export class VisibilityDocumentGuard  {
  constructor(private fundDocumentService: FundDocumentService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let visibility_document_id: ID = route.params.visibility_document_id;

    return this.fundDocumentService.getVisibilityDocument$(visibility_document_id).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) {
          void this.router.navigate(['/404']);
        } else {
          console.error(error);
        }
        return EMPTY;
      }),
      tap({
        next: (visibilityDocument: VisibilityDocument) => {
          void this.router.navigate(['document', visibilityDocument.document_id]);
        }
      }),
      map((res) => !!res)
    );
  }
}
