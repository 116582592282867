/*
  Request Service
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ID } from '@datorama/akita';

import { RenderedFormService } from '@app/shared/components/rendered-form/rendered-form.service';

import { BaseService } from './base.service';
import { Actor, FormService } from './form.service';

@Injectable({ providedIn: 'root' })
export class RequestService extends BaseService {
  request: any;
  currentRequestForm: any;

  constructor(
    private http: HttpClient,
    public formService: FormService,
    public renderedFormService: RenderedFormService
  ) {
    super();
  }

  getFormDefaults(token: string, formId: ID, assignableId: ID, callbacks?: any): void {
    let url = `${this.BASE_URL}/signature_requests/${token}/form/${formId}`;
    if (assignableId) {
      url += `?assignable_id=${assignableId}`;
    }

    this.http.get(url).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (data: any) => {
        // TODO: replicate merge defaults for workflow
        this.renderedFormService.dynamicModels = data.defaults;
        this.currentRequestForm = data;
        let actor: Actor = data.actor;
        // TODO: Think about verifying the current_user with the actor user email
        this.formService.removeElementsWithDifferentRole(this.currentRequestForm.form, actor);

        this.applyCallback('success', callbacks, data);
      }
    });
  }

  create(organizationId: ID, data: any, callbacks?: any): void {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${this.BASE_URL}/organizations/${organizationId}/signature_requests`;

    delete this.error;
    this.success = false;
    this.http.post(url, data).subscribe({
      error: (error) => {
        this.applyCallback('error', callbacks, error);
      },
      next: (createData) => {
        this.success = true;
        this.applyCallback('success', callbacks, createData);
      }
    });
  }
}
