import { Injectable } from '@angular/core';
import { ID, Store, StoreConfig } from '@datorama/akita';

export interface Organization {
  id: ID;
  name: string;
}

// TODO: There is a mismatch between what the user from the cookie and user
// from the requests looks like, however we treat them as one and the same in
// the client, and there are many attributes that may be missing (in particalar,
// from the cookie version of the user which is greatly reduced) – We should either
// Unify what these look like coming from the server, or split them out into seperate
// objects / concerns
export interface User {
  admin: boolean;
  doc_admin: boolean;
  email: string;
  enable_mfa?: boolean;
  exp: number;
  first_name?: string;
  full_name?: string;
  fundmanager: boolean;
  gp?: boolean;
  has_individual_investment_account?: boolean;
  id?: ID;
  initials?: string;
  investment_accounts_count?: number;
  investments_count?: number;
  is_legal_team: boolean;
  jwt_token: string;
  last_name?: string;
  last_organization_id?: string;
  middle_name?: string;
  mobile_phone?: string;
  mobile_verified?: boolean;
  name: string;
  organization?: Organization;
  organization_id?: string;
  organization_member: boolean;
  super_admin?: boolean;
  user_id?: ID;
  vehicle_member: boolean;
}

export interface UserState {
  hasFetchedUser: boolean;
  user?: User;
}

function createInitialState(): UserState {
  return { hasFetchedUser: false, user: undefined };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
