import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@env/environment';

export enum TargetResource {
  Clients = 'clients',
  Entities = 'entities',
  InvestorPortal = 'investor-portal',
  SwitchProfile = 'switch-profile'
}

export interface TargetResourceResponse {
  org_entity_id?: string;
  org_slug?: string;
  target_resource: TargetResource;
}

@Injectable({ providedIn: 'root' })
export class TargetResourceService {
  constructor(private http: HttpClient, private router: Router) {}

  getTargetResource$(): Observable<TargetResourceResponse> {
    return this.http.get<TargetResourceResponse>(`${environment.apiUrl}/user/target_resource`);
  }

  navigateBasedOnResponse(targetResourceResponse: TargetResourceResponse): void {
    let { org_entity_id, org_slug, target_resource } = targetResourceResponse;

    switch (target_resource) {
      case TargetResource.InvestorPortal:
        if (org_slug) {
          window.location.href = `${environment.fliUrl}/g/${org_slug}`;
        } else {
          window.location.href = environment.fliUrl;
        }
        break;
      case TargetResource.Entities:
        void this.router.navigate(['e', org_entity_id, 'entities']);
        break;
      case TargetResource.Clients:
        void this.router.navigate(['e', org_entity_id, 'clients']);
        break;
      default:
        void this.router.navigate(['switch-profile']);
    }
  }
}
