import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';

import { ApplicationStore } from './application.store';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  constructor(private applicationStore: ApplicationStore) {}

  // TODO: Replace occurrences of use of this in the app with better loading solutions
  // (IE: Actual observables vs relying on first loaded instance of a model)
  legacyLoad$(observable$: Observable<any>, name: string): Observable<any> {
    this.pushLoading(name);
    return observable$.pipe(
      filter((obj) => typeof obj !== undefined),
      tap({
        next: () => {
          this.popLoading(name);
        }
      })
    );
  }

  load$<T>(observable$: Observable<T>, name: string): Observable<T> {
    this.pushLoading(name);
    return observable$.pipe(
      finalize(() => {
        this.popLoading(name);
      })
    );
  }

  popLoading(name: string): void {
    this.applicationStore.update((state) => {
      let requests = state.requests.filter((requestName) => name !== requestName);
      return { ...state, requests };
    });
  }

  pushLoading(name: string): void {
    this.applicationStore.update((state) => {
      let requests = [name, ...state.requests];
      return { ...state, requests };
    });
  }

  reset(): void {
    this.applicationStore.reset();
  }

  setNotFound(): void {
    this.applicationStore.update({ notFound: true });
  }
}
