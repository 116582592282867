import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from '@env/environment';

declare let gtag: (action: string, value: string, handler?) => void;

@Injectable()
export class GoogleAnalyticsService {
  constructor(public router: Router) {
    if (environment.production && environment.GOOGLE_ANALYTICS_APP) {
      this.router.events.subscribe({
        next: (event) => {
          if (event instanceof NavigationEnd) {
            gtag('config', environment.GOOGLE_ANALYTICS_APP, {
              page_path: event.urlAfterRedirects
            });
          }
        }
      });
    }
  }

  generateGtagScript(): HTMLScriptElement {
    let gTagScript = document.createElement('script');

    gTagScript.async = true;
    gTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOGLE_ANALYTICS_APP}`;

    return gTagScript;
  }

  generateGtagDataLayerScript(): HTMLScriptElement {
    let gTagDataLayerScript = document.createElement('script');

    gTagDataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${environment.GOOGLE_ANALYTICS_APP}');
    `;

    return gTagDataLayerScript;
  }

  loadGtagScript(): void {
    if (!environment.GOOGLE_ANALYTICS_APP) return;
    document.head.appendChild(this.generateGtagScript());
    document.head.appendChild(this.generateGtagDataLayerScript());
  }
}
