/*
  Builder
  The interactive workspace of a form builder
*/

import { Component, HostListener, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { FormService } from '@app/shared/services';
import { FroalaHelper } from '@app/shared/services/vendor';
import { getVariablesFrom } from '@helpers/string';
import { VariableCreateDialog } from '@app/shared/components/dialogs/variable-create/variable-create.dialog';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-content-editor-dialog',
  styleUrls: ['content-editor.dialog.scss'],
  templateUrl: 'content-editor.dialog.html'
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ContentEditorDialog implements OnInit {
  content: string;
  variablesMissed: string[];
  organizationKeys: string[];

  @HostListener('window:keyup.esc')
  onKeyUp(): void {
    this.dialogRef.close(null);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContentEditorDialog, string>,
    public formService: FormService,
    public froalaHelper: FroalaHelper
  ) {
    this.content = data.content;
    this.variablesMissed = [];
    this.organizationKeys = [];
  }

  ngOnInit() {
    this.formService.searchCustomVariables('', null, null, 'fixed_text');
  }

  validateAndClose(): void {
    this.getOrganizationKeys();

    this.variablesMissed = getVariablesFrom(this.content).filter(
      (myKey) => this.organizationKeys.indexOf(myKey.toUpperCase()) < 0
    );

    this.variablesMissed = this.variablesMissed.filter((v, i, self) => self.indexOf(v) === i);

    if (this.variablesMissed.length < 1) {
      this.dialogRef.close(this.content);
    }
  }

  getOrganizationKeys(): void {
    if (this.organizationKeys.length > 0) return;
    this.organizationKeys = this.formService.formVariables.map((variable) => variable.key);
  }

  openCreateVariableModal(key: any): void {
    const varDialogRef = this.dialog.open(VariableCreateDialog, {
      data: { key },
      width: '500px'
    });

    varDialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result) {
          let resultKey: string = result.key;
          this.organizationKeys.push(resultKey);
          this.validateAndClose();
        }
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }
}
