import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DocumentVersion } from './document-version.model';

export type DocumentVersionsState = EntityState<DocumentVersion>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'document-versions', resettable: true })
export class DocumentVersionsStore extends EntityStore<DocumentVersionsState, DocumentVersion> {
  constructor() {
    super();
  }
}
