import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Query } from '@datorama/akita';

import { AppLayoutState, AppLayoutStore, sidenavCollapsedKey } from './app-layout.store';

@Injectable({ providedIn: 'root' })
export class AppLayoutQuery extends Query<AppLayoutState> {
  sidenavCollapsed$: Observable<boolean> = this.select<boolean>(
    ({ sidenavCollapsed }) =>
      sidenavCollapsed ?? (JSON.parse(localStorage.getItem(sidenavCollapsedKey) ?? 'false') as boolean)
  );

  constructor(protected store: AppLayoutStore) {
    super(store);
  }
}
