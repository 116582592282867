import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { UserState, UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  hasFetchedUser$ = this.select((state) => state?.hasFetchedUser);
  isAdmin$ = this.select((state) => state?.user?.admin);
  user$ = this.select('user');

  constructor(protected store: UserStore) {
    super(store);
  }

  userExists(): boolean {
    return !!this.getValue().user;
  }

  isDocAdmin$(): Observable<boolean> {
    return this.select('user').pipe(map((user) => user?.doc_admin));
  }

  isAdmin(): boolean {
    return this.getValue().user?.admin;
  }

  isDocAdmin(): boolean {
    return this.getValue().user?.doc_admin;
  }
}
