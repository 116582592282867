import { Injectable } from '@angular/core';
import { createStore, select, setProp, setProps, withProps } from '@ngneat/elf';

import { ID } from '@state/base';
import { removeElementsWithPageObjectsFromForm } from '@helpers/form';

import { Form, MemberOnline } from './form.model';

interface FormProps {
  activeSessions: number;
  form?: Form;
  id?: ID;
  membersOnline?: ReadonlyMap<string, MemberOnline>;
  previewForm?: Form;
}

const initialState: FormProps = {
  activeSessions: 0,
  form: undefined
};

@Injectable({ providedIn: 'root' })
export class FormRepository {
  private _store$ = createStore({ name: 'form' }, withProps<FormProps>(initialState));

  activeSessions$ = this._store$.pipe(select(({ activeSessions }) => activeSessions));
  form$ = this._store$.pipe(select(({ form }) => form));
  formId$ = this._store$.pipe(select(({ form }) => form?.id));
  membersOnline$ = this._store$.pipe(select(({ membersOnline }) => membersOnline));
  previewForm$ = this._store$.pipe(
    select(({ previewForm }) => removeElementsWithPageObjectsFromForm(previewForm))
  );
  updatedAt$ = this._store$.pipe(select(({ form }) => form?.updated_at));

  addMemberOnline(id: ID, info: MemberOnline): void {
    if (this._store$.getValue().membersOnline[id]) return;

    this._store$.update((state) => {
      let membersOnline = { ...state.membersOnline };
      membersOnline[id] = { ...info };
      let activeSessions = Object.keys(membersOnline).length;

      return { ...state, activeSessions, membersOnline };
    });
  }

  getPreviewFormId(): ID {
    return this._store$.getValue().previewForm?.id;
  }

  removeMemberOnline(id: ID): void {
    if (!this._store$.getValue().membersOnline[id]) return;

    this._store$.update((state) => {
      let membersOnline = { ...state.membersOnline };
      delete membersOnline[id];
      let activeSessions = Object.keys(membersOnline).length;

      return { ...state, activeSessions, membersOnline };
    });
  }

  reset(): void {
    this._store$.reset();
  }

  setForm(form: Form): void {
    this._store$.update(setProp('form', form));
  }

  setMembersOnline(membersOnline: ReadonlyMap<string, MemberOnline>): void {
    let activeSessions = Object.keys(membersOnline).length;
    membersOnline = { ...membersOnline };
    this._store$.update(setProps({ activeSessions, membersOnline }));
  }

  setPreviewForm(form: Form): void {
    this._store$.update(setProp('previewForm', form));
  }
}
