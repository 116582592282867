import { BaseModel, ID } from '@state/base';
import { PageObjectType } from '@state/form';

export enum DocumentStatus {
  Pending = 'pending',
  Processed = 'processed'
}

export interface SignUploadDocument extends BaseModel {
  allow_download?: boolean;
  document_folder_id?: ID;
  investment_id?: ID;
  logo?: string;
  organization_name?: string;
  owner_legal_name?: string;
  page_count_formatted: string;
  page_count: number;
  preview?: string;
  state: DocumentStatus;
  tags: string[];
  thumbnail?: string;
  title: string;
  vehicle_name?: string;
  vehicle_organization_name?: string;
}

export enum DocumentPreviewType {
  Document = 'document',
  Download = 'download',
  HtmlPreview = 'html-preview',
  Processing = 'processing'
}

export interface PageObject {
  counter_signable: boolean;
  created_at: string;
  display_format?: string;
  document_id: ID;
  document_page_id: ID;
  field_label: string;
  form_field_id: ID;
  form_id: ID;
  height?: number | string;
  id: ID;
  left: number | string;
  object_type: PageObjectType;
  top: number;
  updated_at: string;
  width: number | string;
}

export interface Page {
  height: string;
  id: ID;
  image: string;
  index: number;
  page_objects: PageObject[];
  width: string;
}

// This should reflect the actual payload to the "Document" endpoint at
// `/document/:id`, if there are documents found at other endpoints with
// different serializations an interface should be created for those so we aren't blending
// data concerns (this is especially true here)
// This reflects the DocumentLiteSerializer
export interface Document extends BaseModel {
  allow_download: boolean;
  available_for_download: boolean;
  document_folder_id?: ID;
  investment_id?: ID;
  logo?: string;
  org_url_slug?: string;
  organization_name: string;
  owner_legal_name?: string;
  page_count_formatted?: string;
  page_count?: number;
  pages: Page[];
  preview?: string;
  redirect_url?: string;
  requires_portal_redirect: boolean;
  state: string; // TODO: Create an enum for this state
  tags: string[];
  thumbnail?: string;
  title: string;
  vehicle_name?: string;
  vehicle_organization_name?: string;
}

export interface DocumentDownloadURL {
  download_token: string;
}

export interface DocumentUploadPreview {
  columns: number;
  correct_file_type: boolean;
  data: (string | null)[][];
  preview_data: (string | null)[][];
  rows: number;
}
