import { Injectable } from '@angular/core';

import { AppLayoutStore, sidenavCollapsedKey } from './app-layout.store';

@Injectable({ providedIn: 'root' })
export class AppLayoutService {
  constructor(private appLayoutStore: AppLayoutStore) {}

  toggleMobileMenu(state: boolean): void {
    this.appLayoutStore.update({ mobileMenuActive: state });
  }

  toggleSidenav(): void {
    let collapsed: boolean = JSON.parse(localStorage.getItem(sidenavCollapsedKey) ?? 'false');
    this.appLayoutStore.update({ sidenavCollapsed: !collapsed });
    localStorage.setItem(sidenavCollapsedKey, JSON.stringify(!collapsed));
  }
}
