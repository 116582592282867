import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, filter, finalize, Observable, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ApplicationService } from '@state/application';
import { Entity } from '@state/entities';
import { MembershipEntityRepository } from '@state/membership-entity';
import { ReportService, ReportState } from '@state/report';
import { handleResolverError$ } from '@helpers';

@Injectable({
  providedIn: 'root'
})
export class ReportsResolver  {
  constructor(
    private applicationService: ApplicationService,
    private membershipEntityRepository: MembershipEntityRepository,
    private reportService: ReportService,
    private router: Router,
    private titleService: Title
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ReportState> {
    let providerEntityId: string = route.params.provider_entity_id;
    this.applicationService.pushLoading('reports');

    return this.membershipEntityRepository.membershipEntity$.pipe(
      filter((entity) => entity?.id === route.params.membership_entity_id),
      switchMap((entity: Entity) => this.reportService.getAndStoreReports$(entity?.id, providerEntityId)),
      tap({ next: () => this.titleService.setTitle('Reports | Flow') }),
      catchError((error: HttpErrorResponse) => handleResolverError$(error, this.router)),
      finalize(() => this.applicationService.popLoading('reports'))
    );
  }
}
