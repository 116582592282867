import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Query } from '@datorama/akita';

import { User } from '@state/user';

import { SessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  constructor(
    private cookieService: CookieService,
    protected store: SessionStore,
    public jwtHelper: JwtHelperService
  ) {
    super(store);
  }

  getToken(): string {
    return this.cookieService.get('token');
  }

  loggedIn(): boolean {
    let token = this.getToken();
    return !this.jwtHelper.isTokenExpired(token);
  }

  user(): User {
    if (this.loggedIn()) {
      let token = this.getToken();
      return this.jwtHelper.decodeToken(token);
    }
  }
}
