import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5';
import { Observable } from 'rxjs';

import { ApplicationService } from '@state/application';
import { handleResolverError$ } from '@helpers/error';
import {
  ReportResponse,
  ReportService,
  ReportType
} from '@state/report';

@Injectable({
  providedIn: 'root'
})
export class ReportResolver  {
  constructor(
    private applicationService: ApplicationService,
    private reportService: ReportService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ReportResponse> {
    let entity_id: ID = route.params.membership_entity_id;
    let provider_id: string = route.params.provider_entity_id;
    let report_type: ReportType = route.queryParams.report_type;

    // This is created client-side because an ID is not yet created and we must
    // able to subscribe _prior_ to queuing to the report to ensure we recieve complete
    // and failure messages
    let channel = Md5.hashStr(`report/${entity_id}/${report_type}/${Date.now()}`);
    this.reportService.subscribeToReportStatus(channel);

    this.applicationService.pushLoading('report');

    return this.reportService.queueReport$({
      channel,
      entity_id,
      provider_id,
      report_type
    }).pipe(
      catchError((error: HttpErrorResponse) => handleResolverError$(error, this.router)),
      finalize(() => this.applicationService.popLoading('report'))
    );
  }
}
