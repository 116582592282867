import { ACE_CONFIG, AceConfigInterface, AceModule } from 'ngx-ace-wrapper';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { FlowMaterialModule } from '@app/flow-material.module';
import { FroalaModule } from '@component/froala-editor';
import { PipesModule } from '@app/shared/pipes/pipes.modules';

import { ContentEditorDialog } from './content-editor/content-editor.dialog';
import { SignatureWizardDialogComponent } from './signature-wizard/signature-wizard-dialog.component';

const dialogs = [ContentEditorDialog, SignatureWizardDialogComponent];

const DEFAULT_ACE_CONFIG: AceConfigInterface = {
  navigateWithinSoftTabs: true,
  tabSize: 2,
  useSoftTabs: true
};

@NgModule({
    declarations: dialogs,
    exports: dialogs,
    imports: [
        AceModule,
        CommonModule,
        FlowMaterialModule,
        FormsModule,
        FroalaModule,
        PipesModule.forRoot(),
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: ACE_CONFIG,
            useValue: DEFAULT_ACE_CONFIG
        }
    ]
})

export class FlowDialogsModule {}
