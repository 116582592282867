import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

import { Membership, OrganizationMembership } from './membership.model';

export interface MembershipsState extends EntityState<Membership> {
  membership?: Membership;
  organizationMemberships?: OrganizationMembership[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'memberships', resettable: true })
export class MembershipsStore extends EntityStore<MembershipsState, Membership> {
  constructor() {
    super();
  }
}
