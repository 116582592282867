import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabeledHorizontalSeparatorComponent } from './labeled-horizontal-separator.component';

@NgModule({
  declarations: [LabeledHorizontalSeparatorComponent],
  exports: [LabeledHorizontalSeparatorComponent],
  imports: [CommonModule]
})
export class LabeledHorizontalSeparatorModule {}
